import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function CustomReportIcon(props) {
  return (
    // <SvgIcon {...props} viewBox="0 0 24 24">
    //   <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
    //   <path d="M11 7h2v6h-2zm0 8h2v2h-2z"/>
    // </SvgIcon>
    <SvgIcon {...props} viewBox="0 0 24 24">
      {/* Document shape */}
      <path d="M12 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zm5 18H6V4h5v5h5v11z" />
      {/* Check mark */}
      <path d="M10 15l-2.5-2.5 1.41-1.41L10 12.17l4.09-4.09L16.5 9l-6.5 6z" />
    </SvgIcon>
  );
}

export default CustomReportIcon;
