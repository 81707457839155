import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { message } from "antd";
import useInstituteUser from "./GetInstituteUser";
import axios from "../axiosInstance";
import CourseDetailsVendorAbout from "./CourseDetailsVendorAbout";
import { useDispatch, useSelector } from "react-redux";

const VenderAbout = () => {
  const studentCourse = useInstituteUser();
  const [about, setAbout] = useState([]);
  const [instituteCourses, setInstituteCourses] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  
  // Fetch institute information
  const getInstituteInformation = async () => {
    try {
      const response = await axios.post(`/api/v1/vendor/get-institute-info`, {
        studentCourse,
      });
      setAbout(response?.data?.data);
      localStorage.setItem("instituteInfo", JSON.stringify(response?.data?.data));
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  // const dispatch = useDispatch();
  // const { info, loading, error } = useSelector((state) => state.institute);

  // useEffect(() => {
  //   dispatch(getInstituteInfo(studentCourse));
  // }, [dispatch]);

  // Fetch course details
  const getInstituteCourses = async () => {
    try {
      const response = await axios.post(
        `/api/v1/vendor/institute-get-course-details`,
        {
          studentCourse,
        }
      );
      setInstituteCourses(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };


// Filter courses based on studentCourse array
const filteredCourses = instituteCourses.filter(course => 
  studentCourse.some(sc => sc.studentCourseId === course._id)
);

  useEffect(() => {
    if (studentCourse) {
      Promise.all([getInstituteInformation(), getInstituteCourses()])
        .finally(() => setLoading(false)); 
    }
  }, [studentCourse]);


  if (loading) {
    return <div>Loading...</div>; 
  }


  return (
    <div>
      {about && about?.length > 0 ? (
        <Box sx={{ background: "#f7fafd", p: { md: 3, xs: 1 }, mt: 3 }}>
          <Box sx={{ mr: { md: 3, xs: 1 }, ml: { md: 3, xs: 1 } }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{
                // maxWidth: "800px",
                margin: "0 auto",
                textAlign: "center",
                padding: "20px",
                backgroundColor: "#fff",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
              }}
            >
              <Typography
                style={{
                  textTransform: "capitalize",
                  fontWeight: "700",
                  fontSize: "28px",
                  marginBottom: "10px",
                  color: "#2C3E50",
                  fontFamily: "Poppins, sans-serif",
                  // letterSpacing: "1.5px",
                  letterSpacing: ".1rem",
                }}
              >
                Why Choose{" "}
                <span style={{ color: "#3f51b5" }}>
                  {about[0]?.instituteName}
                </span>
                ?
              </Typography>

              <Typography
                className="vendor_about_description"
                variant="body2"
                color="text.secondary"
                sx={{
                  mt: 1,
                  fontSize: "15px",
                  lineHeight: 1.6,
                  color: "#7F8C8D",
                  letterSpacing: ".1rem",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {about[0]?.description}
              </Typography>
            </Box>

            <Typography
              style={{
                fontWeight: "600px",
                fontSize: "22px",
                textAlign: "center",
              }}
              sx={{ color: "black", pb: 3, mt: 5 }}
            >
              <b> Your Courses</b>
            </Typography>

            <Grid container spacing={3}>
              {filteredCourses?.map((course, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      transition: "transform 0.3s, box-shadow 0.3s",
                      "&:hover": {
                        transform: "translateY(-8px)",
                        boxShadow:
                          "0 8px 16px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
                      },
                      borderRadius: "15px",
                      boxShadow:
                        "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                      padding: "16px",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        className="courses_title"
                        sx={{
                          letterSpacing: ".1rem",
                          fontSize: { xs: "18px", sm: "20px" },
                          marginBottom: "12px",
                          fontWeight: "bold",
                          fontFamily: "Poppins, sans-serif",
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          color: "#2C3E50",
                          // color: "#1E88E5",
                        }}
                      >
                        {course?.courseName}
                      </Typography>
                      <Typography
                        className="courses_desc"
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          mt: 1,
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          overflow: "hidden",
                          fontSize: "15px",
                          letterSpacing: ".1rem",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {course?.courseDescription}
                      </Typography>

                      {/* <Typography
                        className="courses_desc"
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          mt: 2,
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#34495E",
                          letterSpacing: ".1rem",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <strong>Course Start Date: </strong>
                        {new Date(course?.startDate)
                          .toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })
                          .replace(/ /g, "-")}
                      </Typography> */}
                      <Typography
                    className="courses_desc"
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      mt: 2,
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#e74c3c",
                      letterSpacing: ".1rem",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <strong>Course End Date: </strong>
                    {new Date(course?.endDate)
                      .toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })
                      .replace(/ /g, "-")}
                  </Typography>
                      <Box mt={2}>
                        <CourseDetailsVendorAbout instituteId={about[0]?.instituteId} courseId={course?._id} courseName={course?.courseName} courseDesc={course?.courseDescription} courseDate={course?.startDate} courseFees={course?.courseFees} courseEndDate={course?.endDate}/>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>

            <Typography
              style={{
                fontWeight: "600px",
                fontSize: "22px",
                textAlign: "center",
              }}
              sx={{ color: "black", pb: 3, mt: 5 }}
            >
              <b> Institute All Courses</b>
            </Typography>

            <Grid container spacing={3}>
              {instituteCourses?.map((course, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      transition: "transform 0.3s, box-shadow 0.3s",
                      "&:hover": {
                        transform: "translateY(-8px)",
                        boxShadow:
                          "0 8px 16px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
                      },
                      borderRadius: "15px",
                      boxShadow:
                        "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                      padding: "16px",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        className="courses_title"
                        sx={{
                          letterSpacing: ".1rem",
                          fontSize: { xs: "18px", sm: "20px" },
                          marginBottom: "12px",
                          fontWeight: "bold",
                          fontFamily: "Poppins, sans-serif",
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          color: "#2C3E50",
                          // color: "#1E88E5",
                        }}
                      >
                        {course?.courseName}
                      </Typography>
                      <Typography
                        className="courses_desc"
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          mt: 1,
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          overflow: "hidden",
                          fontSize: "15px",
                          letterSpacing: ".1rem",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {course?.courseDescription}
                      </Typography>

                      <Typography
                        className="courses_desc"
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          mt: 2,
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#34495E",
                          letterSpacing: ".1rem",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <strong>Course Start Date: </strong>
                        {new Date(course?.startDate)
                          .toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })
                          .replace(/ /g, "-")}
                      </Typography>
                      <Box mt={2}>
                        <CourseDetailsVendorAbout courseId={course?._id} courseName={course?.courseName} courseDesc={course?.courseDescription} courseDate={course?.startDate} courseFees={course?.courseFees} courseEndDate={course?.endDate}/>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>


          </Box>
        </Box>
      ) : null}
    </div>
  );
};

export default VenderAbout;
