import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  CircularProgress,
  Chip,
  Breadcrumbs, Link as MUILink, 
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "../../axiosInstance";
import { message } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import EditorVendorList from "./EditorVendorList";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const EditorVendor = () => {
  const [value, setValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  const [users, setUsers] = useState([]);
  const [vendorName, setVendorName] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [vendorPhone, setVendorPhone] = useState("");
  const [vendorInstituteId, setVendorInstituteId] = useState(user?.instituteId);
  const [addPermissionId, setAddPermissionId] = useState("");
  const [instituteAccounts, setInstituteAccounts] =
    useState("Teacher Accounts");
  const [role, setRole] = useState([]);
  const [addRolePermission, setAddRolePermission] = useState("");
  const [role_name, setRole_name] = useState("");
  const [addRouterPermission, setAddRouterPermission] = useState("");
  const [getRouterPermission, setGetRouterPermission] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [addPermission, setAddPermission] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [editorRole, setEditorRole] = useState([]);
  const [editorId, setEditorId] = useState("");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imageBase64, setImageBase64] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(startDate);
  const [vendorPlans, setVendorPlans] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Upload button functionality
  const VisuallyHiddenInput = styled("input")({
    display: "none",
  });
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Create a URL for preview
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
      // Convert image to base64
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageBase64(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const planStartDate = moment(startDate).format("MM/DD/YYYY");
  const planEndDate = moment(endDate).format("MM/DD/YYYY");

  // Get All Vendor Plan
  const getAllVendorPlan = async () => {
    try {
      const response = await axios.get(`/api/v1/plan/admin-get-plan`);
      if (response?.data?.success === true) {
        setVendorPlans(response?.data?.plan);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Failed to fetch plans");
    }
  };
  useEffect(() => {
    getAllVendorPlan();
  }, []);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleChangeVendorPlan = (event) => {
    setSelectedPlanId(event.target.value);
  };

  // Create a New Sub-Admin and Assign Plan
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const formData = new FormData();
    formData.append("name", vendorName);
    formData.append("email", vendorEmail);
    formData.append("phone", vendorPhone);
    formData.append("instituteId", user?.instituteId);
    formData.append(
      "permissions",
      JSON.stringify([
        {
          id: addPermissionId,
          value: addPermission,
        },
      ])
    );
    formData.append("role", addRolePermission);
    formData.append("instituteAccounts", instituteAccounts);
    formData.append("assignVendorId", user?._id);
    formData.append("permission_id", addPermissionId);
    formData.append("assignCategoryPermissions", user?._id);
    formData.append("logo", imageBase64);
    formData.append("role_name", role_name);

    try {
      // First API call to create a new sub-admin
      const { data: createData } = await axios.post(
        "/api/v1/common/admin-create-editor-vendor",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (createData.success === true) {
        message.success(createData.message);

        const vendorId = createData.vendorId;

        const AssignPlanData = {
          startDate: planStartDate,
          endDate: planEndDate,
          userId: vendorId,
          planId: selectedPlanId,
        };

        try {
          const { data: assignPlanData } = await axios.post(
            "/api/v1/plan/admin-assign-plan-to-user",
            AssignPlanData
          );
          if (assignPlanData.success === true) {
            // message.success(assignPlanData.message);
          } else {
            message.error(assignPlanData?.message);
          }
        } catch (error) {
          message.error(error?.response?.data?.message);
        }
        // Clear form fields after both calls are successful
        setVendorName("");
        setVendorEmail("");
        setVendorPhone("");
        setAddPermissionId(null);
        setAddPermission([]);
        setPermissions(null);
        setRole_name(null);
        setAddRolePermission(null);
      } else {
        message.error(createData?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Get Permission Data
  const getAllPermission = async () => {
    try {
      const response = await axios.get(`/api/v1/admin/admin-get-permission`);
      if (response?.data?.success === true) {
        setPermissions(response?.data?.permission);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllPermission();
    return () => clearInterval(intervalId);
  }, []);

  const AddPermissionHandler = (e) => {
    const selectedValue = e.target.value;
    setAddPermissionId(selectedValue);
  };

  const AddRoleHandler = (e) => {
    const selectedValue = e.target.value;
    setAddRolePermission(selectedValue?.value);
    setRole_name(selectedValue?.role_name);
  };

  // Get Role Data
  const getAllRole = async () => {
    try {
      const response = await axios.get(`/api/v1/admin/admin-get-role`);
      if (response?.data?.success === true) {
        setRole(response?.data?.role);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllRole();
    return () => clearInterval(intervalId);
  }, []);

  // Role and Editor Id Handle Function
  const AddVendorRoleHandler = (e) => {
    const selectedValue = e.target.value;
    setEditorRole(selectedValue?.role);
    setEditorId(selectedValue?._id);
  };

  // get-all-users
  const displayUsers = async () => {
    try {
      const { data } = await axios.get("/api/v1/auth/admin-get-all-user");
      setUsers(data?.user);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    displayUsers();
    return () => clearInterval(intervalId);
  }, []);

  const getMenuItemLabel = (value) => {
    switch (value) {
      case 0:
        return "Create";
      case 1:
        return "Read";
      case 2:
        return "Update";
      case 3:
        return "Delete";
      default:
        return "";
    }
  };

  return (
    <>
      <PageTitle title="Admin Editors | Test Series" />
      <Box>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Sub-Vendors
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="#"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Super Admin
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>Sub-Vendors</Typography>
        </Breadcrumbs>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Sub-Vendors List" {...TabPanel(0)} />
            <Tab label="Add Sub-Vendors" {...TabPanel(1)} />
          </Tabs>
        </Box>
        {/* Editor Vendor List  */}
        <CustomTabPanel value={value} index={0}>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            <EditorVendorList />
          </Typography>
        </CustomTabPanel>
        {/*  Add Roles */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Create a new Sub-Vendor Account
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="instituteId"
              label="Institute Id *"
              name="instituteId"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Editor Institute Id for example GatikAi-01"
              value={vendorInstituteId}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendorName"
              label="Full Name *"
              name="vendorName"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Full Name"
              value={vendorName}
              onChange={(e) => setVendorName(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendorEmail"
              label="Email *"
              name="vendorEmail"
              InputLabelProps={{
                shrink: true,
              }}
              type="email"
              placeholder="Email"
              value={vendorEmail}
              onChange={(e) => setVendorEmail(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendorPhone"
              label="Phone Number *"
              name="vendorPhone"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Phone Number"
              type="number"
              value={vendorPhone}
              onChange={(e) => setVendorPhone(e.target.value)}
            />
            {/* Roles */}
            <Box sx={{ width: "100%", mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Add Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Add Role"
                  onChange={AddRoleHandler}
                  required
                >
                  {role && role.length > 0 ? (
                    role.map((role, index) => (
                      <MenuItem key={index} value={role}>
                        {/* {role.value} */}
                        {role?.role_name}
                      </MenuItem>
                    ))
                  ) : (
                    <Box
                      style={{
                        color: "#878787",
                        margin: "10px",
                        fontSize: 18,
                      }}
                    >
                      No available Roles
                    </Box>
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "100%", mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Permission Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Permission Name"
                  onChange={AddPermissionHandler}
                  required
                >
                  {permissions && permissions.length > 0 ? (
                    permissions.map((permission, index) => (
                      <MenuItem key={index} value={permission._id}>
                        {permission.permission_name}
                      </MenuItem>
                    ))
                  ) : (
                    <Box
                      style={{
                        color: "#878787",
                        margin: "10px",
                        fontSize: 18,
                      }}
                    >
                      No available Permission
                    </Box>
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "100%", mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {" "}
                  Add Permission{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={addPermission}
                  required
                  label="Add Permission"
                  multiple
                  onChange={(e) => setAddPermission(e.target.value)}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip
                          sizes="medium"
                          key={value}
                          label={getMenuItemLabel(value)}
                        />
                      ))}
                    </div>
                  )}
                >
                  <MenuItem value={0}>Create</MenuItem>
                  <MenuItem value={1}>Read</MenuItem>
                  <MenuItem value={2}>Update</MenuItem>
                  <MenuItem value={3}>Delete</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 3, mt: 3 }}>
                <InputLabel shrink id="plan-label">
                  Plan Name *
                </InputLabel>
                <Select
                  label="plan-label"
                  id="plan-select"
                  value={selectedPlanId}
                  onChange={handleChangeVendorPlan}
                  displayEmpty
                  sx={{
                    "& .MuiInputBase-root": {
                      color: "text.secondary",
                    },
                    fieldset: { borderColor: "rgb(231, 235, 240)" },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select a Plan
                  </MenuItem>
                  {vendorPlans.map((plan) => (
                    <MenuItem key={plan._id} value={plan._id}>
                      {plan.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box style={{ display: "flex" }} className="Date">
                <Box
                  sx={{
                    mb: 3,
                  }}
                  style={{ marginRight: "2%" }}
                >
                  <DatePicker
                    showIcon
                    toggleCalendarOnIconClick
                    isClearable
                    placeholderText="Assign Plan Start Date"
                    selected={startDate}
                    value={startDate}
                    onChange={handleStartDateChange}
                    minDate={new Date()}
                    maxDate={endDate}
                    required
                  />
                </Box>
                <Box>
                  <DatePicker
                    required
                    showIcon
                    toggleCalendarOnIconClick
                    isClearable
                    placeholderText="Assign Plan End Date"
                    selected={endDate}
                    onChange={handleEndDateChange}
                    value={endDate}
                    // eslint-disable-next-line no-mixed-operators
                    minDate={(new Date() && startDate) || new Date()}
                    maxDate={endDate}
                  />
                </Box>
              </Box>

              <Box sx={{ mt: 3 }}>
                <VisuallyHiddenInput
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={handleImageUpload}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="outlined"
                    color="primary"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Institute logo
                  </Button>
                </label>
                {image && (
                  <Box mt={3} display="flex" alignItems="center">
                    <img
                      src={image}
                      alt="avatar"
                      style={{
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <Button
              sx={{ mt: 3 }}
              variant="contained"
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Create Sub-Vendors"}
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
};
export default EditorVendor;
