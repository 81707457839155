import React, { useEffect, useState } from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import {
  Box,
  Paper,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { message, Modal } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import axios from "../../axiosInstance";
import { useSelector } from "react-redux";

const SubjectiveExam = () => {
  const { user } = useSelector((state) => state.user);
  const [exams, setExams] = useState([]);
  const [filteredExams, setFilteredExams] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  // Get All Exam Data
  const getAllSubjectiveExams = async () => {
    try {
      const response = await axios.post(
        `/api/v1/subjective/admin-get-all-exams-subjective`
      );
      if (response?.data?.success === true) {
        setExams(response?.data?.data);
        setFilteredExams(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllSubjectiveExams();
  }, []);

  // Delete Subjective Exam
  const deleteExam = async (e, id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Subjective Exam?",
      content:
        "This action will permanently delete the subjective exam. Are you sure you want to continue?",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/subjective/admin-delete-exam-subjective",
            {
              data: { id },
            }
          );
          if (data.success === true) {
            message.success(data.message);
            getAllSubjectiveExams();
          } else {
            message.error(data.message);
          }
        } catch (error) {
          message.error(error?.response?.data?.message || "Error occurred");
        }
      },
      onCancel() {
        console.log("Deletion canceled");
      },
    });
  };

  // Filter exams based on search query and selected category
  useEffect(() => {
    let filtered = exams;

    if (searchQuery) {
      filtered = filtered?.filter((exam) =>
        exam?.name?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (selectedCategory) {
      filtered = filtered?.filter(
        (exam) => exam?.category === selectedCategory
      );
    }

    setFilteredExams(filtered);
  }, [searchQuery, selectedCategory, exams]);

  const handleSearch = (e) => {
    setSearchQuery(e?.target?.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e?.target?.value);
  };

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 20,
      sortable: false,
    },
    {
      field: "Actions",
      width: 90,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteExam(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <Link to={`/admin/edit/subjective/exam/${value?.row?._id}`}>
            <IconButton aria-label="edit">
              <EditIcon sx={{ color: "#1976d2" }} />
            </IconButton>
          </Link>
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Exam Name",
      width: 140,
      editable: true,
    },
    // {
    //   field: "instituteId",
    //   headerName: "Institute Id",
    //   width: 110,
    //   editable: true,
    // },
    {
      field: "category",
      headerName: "Category",
      width: 110,
      editable: true,
    },
    {
      field: "examSet",
      headerName: "Exam Type",
      width: 110,
      editable: true,
    },
    {
      field: "subjectiveQuestions",
      headerName: "Total Questions",
      width: 100,
      editable: true,
      renderCell: (row) => row?.row?.subjectiveQuestions?.length,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 100,
      editable: true,
    },
    {
      field: "totalMarks",
      headerName: "Total Marks",
      width: 120,
      editable: true,
    },
    {
      field: "passingMarks",
      headerName: "Passing Marks",
      width: 120,
      editable: true,
    },
    {
      field: "negativeMarking",
      headerName: "Negative Marks",
      width: 110,
      editable: true,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 120,
      editable: true,
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 120,
      editable: true,
    },
  ];
  // Conditionally add the instituteId column if user.role === 1
  if (user && user.role === 1) {
    columns.splice(2, 0, {
      field: "instituteId",
      headerName: "Institute Id",
      width: 180,
      editable: true,
    });
  }

  return (
    <div>
      <PageTitle title="Admin Subjective Exams | Test Series" />
      <Box>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Subjective Exams
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>
            Subjective Exams
          </Typography>
        </Breadcrumbs>
        <Box
          style={{
            width: "auto",
            overflowY: "hidden",
            overflowX: "scroll",
          }}
        >
          <Box
            sx={{
              pb: 2,
              display: "flex",
              width: "auto",
              justifyContent: "left",
            }}
          >
            <Button
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              className="courses_desc"
              sx={{
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              <Link
                style={{ color: "white", textDecoration: "none" }}
                to="/admin/add/subjective/exam"
              >
                Create Subjective Exam
              </Link>{" "}
            </Button>
          </Box>
          {/* Search Input */}
          <Box sx={{ pb: 2, mt: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search by Exam Name"
              value={searchQuery}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon sx={{ color: "#1976d2" }} />
                  </IconButton>
                ),
              }}
              sx={{
                mb: 3,
                bgcolor: "white",
                borderRadius: "20px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ pb: 2 }}>
            <FormControl
              fullWidth
              sx={{
                mb: 3,
                bgcolor: "white",
                borderRadius: "20px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
            >
              <InputLabel>Filter by Category</InputLabel>
              <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                label="Category"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {Array.from(new Set(exams.map((exam) => exam.category))).map(
                  (category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Box>
          <Paper sx={{ bgcolor: "white" }}>
            <Box sx={{ height: 500, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row?._id}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                  // Style for header cells
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                    // fontSize: "13px",
                  },
                  // Style for checkbox in header
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                rows={filteredExams.map((row, index) => ({
                  ...row,
                  rowNumber: index + 1,
                }))}
                // rows={filteredExams}
                columns={columns}
                pageSize={3}
                rowsPerPageOptions={[3]}
                // checkboxSelection
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </div>
  );
};

export default SubjectiveExam;
