// import React, { useState, useEffect } from "react";
// import axios from "../../axiosInstance";
// import {
//   Box,
//   Typography,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   CircularProgress,
//   Button,
//   TextField, 
//   IconButton,
// } from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
// import { message, Modal } from "antd";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import DownloadIcon from "@mui/icons-material/Download";
// import PageTitle from "../../Components/PageTitle/PageTitle";
// import { useSelector } from "react-redux";

// const SubjectiveTopRank = () => {
//   const { user } = useSelector((state) => state.user);
//   const [loading, setLoading] = useState(true);
//   const [topRankData, setTopRankData] = useState([]);
//   const [filteredReports, setFilteredReports] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           "/api/v1/reports/subjective/admin-get-top-rank-subjectiveReport"
//         ); 
//         const data = response.data.topRankData;
//         const sortedData = data.sort(
//           (a, b) => b.totalMarksObtained - a.totalMarksObtained
//         );
//         setTopRankData(sortedData);
//         setFilteredReports(sortedData); // Fixed this line
//       } catch (error) {
//         message.error(error?.response?.data?.message);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, []);

//   // Function to export data to PDF
// const exportPDF = () => {
//   Modal.confirm({
//     title: "Confirm Download",
//     content: "Do you want to download the Top Rank Reports as a PDF?",
//     okText: "Yes, Download",
//     cancelText: "No, Cancel",
//     onOk: () => {
//       const doc = new jsPDF();
//       const columns = [
//         "#",
//         "Student Name",
//         "Student Email",
//         "Exam Name",
//         "Student Score",
//       ];
//       const rows = topRankData.map((item, index) => [
//         index + 1,
//         item.userName || "N/A",
//         item.userEmail || "N/A",
//         item.examName || "N/A",
//         `${item.totalMarksObtained || 0} / ${item?.totalMarks || 0}`,
//       ]);

//       // Add title
//       doc.text("Top Rank Reports", 14, 10);

//       // Generate the PDF with autotable
//       doc.autoTable({
//         head: [columns],
//         body: rows,
//         startY: 20,
//       });

//       // Save the PDF
//       doc.save("Top_Rank_Reports.pdf");
//     },
//     onCancel() {
//       console.log("Download canceled");
//     },
//   });
// };


//   // Search functionality
//   const handleSearch = (event) => {
//     const query = event.target.value.toLowerCase();
//     setSearchQuery(query);

//     const filtered = topRankData.filter((report) => {
//       return (
//         report.examName.toLowerCase().includes(query) ||
//         report.userEmail.toLowerCase().includes(query)
//       );
//     });

//     setFilteredReports(filtered); // Fixed this line
//   };

//   return (
//     <>
//       <PageTitle title="Admin Subjective Exam TopRank | Test Series" />
//       <Box sx={{ minHeight: "100vh" }}>
//         <Typography  variant="h5"
//           align="center"
//           gutterBottom
//           sx={{
//             mb: 3,
//             fontFamily: "Poppins, sans-serif",
//             color: "#2C3E50",
//             letterSpacing: "2.5px",
//             lineHeight: 1.8,
//           }}>
//           All Subjective Exam Top Rank List
//         </Typography>

//         <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
//         <TextField
//           fullWidth
//           variant="outlined"
//           placeholder="Search by Exam Name or User Email"
//           value={searchQuery}
//           onChange={handleSearch}
//           InputProps={{
//             startAdornment: (
//               <IconButton>
//                 <SearchIcon sx={{ color: "#1976d2" }} />
//               </IconButton>
//             ),
//           }}
//           sx={{
//             mb: 3,
//             bgcolor: "white",
//             borderRadius: "20px",
//             boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
//             "& .MuiOutlinedInput-root": {
//               "& fieldset": {
//                 border: "none",
//               },
//               "&:hover fieldset": {
//                 border: "none",
//               },
//               "&.Mui-focused fieldset": {
//                 border: "none",
//               },
//             },
//           }}
//         />
//       </Box>

//         {filteredReports?.length > 0 && (
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={exportPDF}
//             startIcon={<DownloadIcon />}
//         className="courses_desc"
//         sx={{
//           mb: 2,
//           display: "flex",
//           justifyContent: "space-between",
//           borderRadius: "5px",
//           textTransform: "none",
//           fontFamily: "Poppins, sans-serif",
//           letterSpacing: ".1rem",
//         }}
//           >
//             Export to PDF
//           </Button>
//         )}

//         {loading ? (
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               minHeight: "50vh",
//             }}
//           >
//             <CircularProgress />
//           </Box>
//         ) : filteredReports?.length === 0 ? (
//           <Typography variant="h6" sx={{ textAlign: "center", mt: 5 }}>
//             No Reports Available at This Time
//           </Typography>
//         ) : (
//           <Paper sx={{ bgcolor: "white" }}>
//             <TableContainer component={Paper}>
//               <Table>
//                 <TableHead
//                   sx={{ backgroundColor: "rgb(25, 118, 210)", color: "white" }}
//                 >
//                   <TableRow>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Top Rank
//                     </TableCell>
//                     {user && user?.role === 1 && (
//                 <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                   Institute Id
//                 </TableCell>
//               )}
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Student Name
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Student Email
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Exam Name
//                     </TableCell>
//                     <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                       Student Score
//                     </TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {filteredReports?.map((item, index) => (
//                     <TableRow
//                       key={item?._id}
//                       sx={{
//                         "&:nth-of-type(even)": { bgcolor: "#f5f5f5" },
//                         "&:nth-of-type(odd)": { bgcolor: "#ffffff" },
//                         ...(index === 0 ? { backgroundColor: "#dbe3e8" } : {}),
//                       }}
//                     >
//                       <TableCell>{index + 1}</TableCell>
//                       {user && user?.role === 1 && (
//                       <TableCell>{item?.instituteId || "N/A"}</TableCell>
//                       )}
//                       <TableCell>{item?.userName || "N/A"}</TableCell>
//                       <TableCell>{item?.userEmail || "N/A"}</TableCell>
//                       <TableCell>{item?.examName || "N/A"}</TableCell>
//                       <TableCell>
//                         {item?.totalMarksObtained || 0} /{" "}
//                         {item?.totalMarks || 0}
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </Paper>
//         )}
//       </Box>
//     </>
//   );
// };

// export default SubjectiveTopRank;


import React, { useState, useEffect } from "react";
import axios from "../../axiosInstance";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Button,
  TextField,
  IconButton,
  TablePagination, // Import TablePagination
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { message, Modal } from "antd";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DownloadIcon from "@mui/icons-material/Download";
import PageTitle from "../../Components/PageTitle/PageTitle";
import { useSelector } from "react-redux";

const SubjectiveTopRank = () => {
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [topRankData, setTopRankData] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0); // Pagination page state
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "/api/v1/reports/subjective/admin-get-top-rank-subjectiveReport"
        );
        const data = response.data.topRankData;
        const sortedData = data.sort(
          (a, b) => b.totalMarksObtained - a.totalMarksObtained
        );
        setTopRankData(sortedData);
        setFilteredReports(sortedData); // Fixed this line
      } catch (error) {
        message.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Function to export data to PDF
  const exportPDF = () => {
    Modal.confirm({
      title: "Confirm Download",
      content: "Do you want to download the Top Rank Reports as a PDF?",
      okText: "Yes, Download",
      cancelText: "No, Cancel",
      onOk: () => {
        const doc = new jsPDF();
        const columns = [
          "#",
          "Student Name",
          "Student Email",
          "Exam Name",
          "Student Score",
        ];
        const rows = topRankData.map((item, index) => [
          index + 1,
          item.userName || "N/A",
          item.userEmail || "N/A",
          item.examName || "N/A",
          `${item.totalMarksObtained || 0} / ${item?.totalMarks || 0}`,
        ]);

        // Add title
        doc.text("Top Rank Reports", 14, 10);

        // Generate the PDF with autotable
        doc.autoTable({
          head: [columns],
          body: rows,
          startY: 20,
        });

        // Save the PDF
        doc.save("Top_Rank_Reports.pdf");
      },
      onCancel() {
        console.log("Download canceled");
      },
    });
  };

  // Search functionality
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = topRankData.filter((report) => {
      return (
        report.examName.toLowerCase().includes(query) ||
        report.userEmail.toLowerCase().includes(query)
      );
    });

    setFilteredReports(filtered); // Fixed this line
  };

  // Handle page change for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page change
  };

  // Get the current rows based on pagination
  const currentRows = filteredReports.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <PageTitle title="Admin Subjective Exam TopRank | Test Series" />
      <Box sx={{ minHeight: "100vh" }}>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          All Subjective Exam Top Rank List
        </Typography>

        <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by Exam Name or User Email"
            value={searchQuery}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon sx={{ color: "#1976d2" }} />
                </IconButton>
              ),
            }}
            sx={{
              mb: 3,
              bgcolor: "white",
              borderRadius: "20px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
              },
            }}
          />
        </Box>

        {filteredReports?.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            onClick={exportPDF}
            startIcon={<DownloadIcon />}
            className="courses_desc"
            sx={{
              mb: 2,
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "5px",
              textTransform: "none",
              fontFamily: "Poppins, sans-serif",
              letterSpacing: ".1rem",
            }}
          >
            Export to PDF
          </Button>
        )}

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : filteredReports?.length === 0 ? (
          <Typography variant="h6" sx={{ textAlign: "center", mt: 5 }}>
            No Reports Available at This Time
          </Typography>
        ) : (
          <Paper sx={{ bgcolor: "white" }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead
                  sx={{ backgroundColor: "rgb(25, 118, 210)", color: "white" }}
                >
                  <TableRow>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Top Rank
                    </TableCell>
                    {user && user?.role === 1 && (
                      <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                        Institute Id
                      </TableCell>
                    )}
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Student Name
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Student Email
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Exam Name
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Student Score
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentRows?.map((item, index) => (
                    <TableRow
                      key={item?._id}
                      sx={{
                        "&:nth-of-type(even)": { bgcolor: "#f5f5f5" },
                        "&:nth-of-type(odd)": { bgcolor: "#ffffff" },
                        ...(index === 0 ? { backgroundColor: "#dbe3e8" } : {}),
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      {user && user?.role === 1 && (
                        <TableCell>{item?.instituteId || "N/A"}</TableCell>
                      )}
                      <TableCell>{item?.userName || "N/A"}</TableCell>
                      <TableCell>{item?.userEmail || "N/A"}</TableCell>
                      <TableCell>{item?.examName || "N/A"}</TableCell>
                      <TableCell>
                        {item?.totalMarksObtained || 0} /{" "}
                        {item?.totalMarks || 0}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {/* Add Pagination */}
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredReports.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Paper>
        )}
      </Box>
    </>
  );
};

export default SubjectiveTopRank;
