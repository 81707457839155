// import React, { useState, useEffect } from "react";
// import { Box, Typography, Paper, TextField, Button } from "@mui/material";
// import { useLocation, useNavigate } from "react-router-dom";
// import axios from "../../axiosInstance";
// import { message } from "antd";

// const ShareReport = () => {
//   const { state } = useLocation();
//   const navigate = useNavigate();
//   const currentDate = new Date().toLocaleDateString();
//   const [instituteInfo, setInstituteInfo] = useState([]);
//   const [instituteUserInfo, setInstituteUserInfo] = useState([]);

//   const [reportData, setReportData] = useState({
//     ...state?.report,
//     academicSession: "2024-25",
//     date: currentDate,
//     courseName: "",
//     classSignature: "",
//     principalSignature: "",
//   });

//   useEffect(() => {
//     const fetchCourseName = async () => {
//       if (reportData.instituteCourseId) {
//         try {
//           const response = await axios.post(
//             "/api/v1/vendor/admin/get-course-details-by-id-institute",
//             { instituteCourseId: reportData?.instituteCourseId }
//           );
//           setReportData((prev) => ({
//             ...prev,
//             courseName: response.data?.data?.courseName || "",
//           }));
//         } catch (error) {
//           console.error("Error fetching course name:", error);
//         }
//       }
//     };
//     fetchCourseName();
//   }, [reportData.instituteCourseId]);

//   useEffect(() => {
//     const getAllInstituteInfo = async () => {
//       try {
//         const response = await axios.get(
//           `/api/v1/vendor/admin-get-about-institute`
//         );
//         if (response?.data?.success === true) {
//           setInstituteInfo(response?.data?.data);
//         }
//       } catch (error) {
//         message.error(error?.response?.data?.message);
//       }
//     };
//     getAllInstituteInfo();
//   }, []);

//   useEffect(() => {
//     const fetchUserInfoByUserId = async () => {
//       if (reportData?.userId) {
//         try {
//           const response = await axios.post(
//             "/api/v1/batch/admin-get-institute-user-info-by-id-batch",
//             { userId: reportData?.userId }
//           );
//           if (response?.data?.success === true) {
//             setInstituteUserInfo(response?.data?.user);
//           }
//         } catch (error) {
//           console.error("Error fetching course name:", error);
//         }
//       }
//     };
//     fetchUserInfoByUserId();
//   }, [reportData?.userId]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setReportData((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   const handleShareReport = async () => {
//     try {
//       const response = await axios.post(
//         "/admin/share/report/student",
//         reportData
//       );
//       message.success("Report shared successfully!");
//       navigate("/admin/reports");
//     } catch (error) {
//       message.error("Failed to share report.");
//     }
//   };

//   const handleShowPreview = () => {
//     navigate("/admin/share/report/preview", { state: { reportData, instituteInfo, instituteUserInfo } });
//   };

//   return (
//     <Box sx={{ padding: 3 }}>
//       <Typography variant="h5" align="center" gutterBottom>
//         Edit Report Details
//       </Typography>
//       <Paper sx={{ padding: 3, margin: "0 auto" }}>
//         <TextField
//           fullWidth
//           label="Exam Name"
//           name="examName"
//           value={reportData.examName || ""}
//           onChange={handleChange}
//           margin="normal"
//         />
//         <TextField
//           fullWidth
//           label="Student Email"
//           name="userEmail"
//           value={reportData.userEmail || ""}
//           onChange={handleChange}
//           margin="normal"
//         />
//         <TextField
//           fullWidth
//           label="Total Marks"
//           name="totalMarks"
//           value={reportData.totalMarks || ""}
//           onChange={handleChange}
//           margin="normal"
//         />
//         <TextField
//           fullWidth
//           label="Obtained Marks"
//           name="obtainedMarks"
//           value={reportData.result?.obtainedMarks || ""}
//           onChange={(e) =>
//             handleChange({
//               target: { name: "result.obtainedMarks", value: e.target.value },
//             })
//           }
//           margin="normal"
//         />
//         <TextField
//           fullWidth
//           label="Verdict"
//           name="verdict"
//           value={reportData.result?.verdict || ""}
//           onChange={(e) =>
//             handleChange({
//               target: { name: "result.verdict", value: e.target.value },
//             })
//           }
//           margin="normal"
//         />
//         {/* Course Name (Fetched from API) */}
//         <TextField
//           fullWidth
//           label="Course / Class Name"
//           name="courseName"
//           value={reportData.courseName}
//           onChange={handleChange}
//           margin="normal"
//           disabled // Make it read-only since it's fetched from the API
//         />

//         <TextField
//           fullWidth
//           label="Academic Session"
//           name="academicSession"
//           value={reportData.academicSession}
//           onChange={handleChange}
//           margin="normal"
//         />

//         <TextField
//           fullWidth
//           label="Current Date"
//           name="date"
//           value={reportData.date}
//           onChange={handleChange}
//           margin="normal"
//         />

//         <TextField
//           fullWidth
//           label="Class Teacher Name"
//           name="classSignature"
//           value={reportData.classSignature}
//           onChange={handleChange}
//           margin="normal"
//         />

//         <TextField
//           fullWidth
//           label="Principal Name"
//           name="principalSignature"
//           value={reportData.principalSignature}
//           onChange={handleChange}
//           margin="normal"
//         />

//         <Button onClick={handleShareReport} variant="contained" color="primary">
//           Share Report
//         </Button>
//         <Button onClick={handleShowPreview} variant="outlined" sx={{ ml: 2 }}>
//           Show Preview
//         </Button>
//       </Paper>
//     </Box>
//   );
// };

// export default ShareReport;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../axiosInstance";
import { message } from "antd";

const ShareReport = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const currentDate = new Date().toLocaleDateString();
  const [instituteInfo, setInstituteInfo] = useState([]);
  const [instituteUserInfo, setInstituteUserInfo] = useState([]);

  //   // Initialize report data from state or sessionStorage
  //   const [reportData, setReportData] = useState(() => {
  //     const savedData = sessionStorage.getItem("reportData");
  //     return savedData ? JSON.parse(savedData) : {
  //       ...state?.report,
  //       academicSession: "2024-25",
  //       date: currentDate,
  //       courseName: "",
  //       classSignature: "",
  //       principalSignature: "",
  //     };
  //   });

  const [reportData, setReportData] = useState({
    ...state?.report,
    academicSession: "2024-25",
    date: currentDate,
    courseName: "",
    classSignature: "",
    principalSignature: "",
  });

  useEffect(() => {
    const fetchCourseName = async () => {
      if (reportData?.instituteCourseId) {
        try {
          const response = await axios.post(
            "/api/v1/vendor/admin/get-course-details-by-id-institute",
            { instituteCourseId: reportData?.instituteCourseId }
          );
          setReportData((prev) => ({
            ...prev,
            courseName: response.data?.data?.courseName || "",
          }));
        } catch (error) {
          console.error("Error fetching course name:", error);
        }
      }
    };
    fetchCourseName();
  }, [reportData?.instituteCourseId]);

  useEffect(() => {
    const getAllInstituteInfo = async () => {
      try {
        const response = await axios.get(
          `/api/v1/vendor/admin-get-about-institute`
        );
        if (response?.data?.success === true) {
          setInstituteInfo(response?.data?.data);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    };
    getAllInstituteInfo();
  }, []);

  useEffect(() => {
    const fetchUserInfoByUserId = async () => {
      if (reportData?.userId) {
        try {
          const response = await axios.post(
            "/api/v1/batch/admin-get-institute-user-info-by-id-batch",
            { userId: reportData?.userId }
          );
          if (response?.data?.success === true) {
            setInstituteUserInfo(response?.data?.user);
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      }
    };
    fetchUserInfoByUserId();
  }, [reportData?.userId]);

  useEffect(() => {
    // Save reportData in session storage to retain data after preview
    sessionStorage.setItem("reportData", JSON.stringify(reportData));
  }, [reportData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReportData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNestedChange = (name, value) => {
    setReportData((prev) => ({
      ...prev,
      result: {
        ...prev.result,
        [name]: value,
      },
    }));
  };

  const handleShareReport = async () => {
    try {
      await axios.post("/admin/share/report/student", reportData);
      message.success("Report shared successfully!");
      sessionStorage.removeItem("reportData"); // Clear stored data after successful submission
      navigate("/admin/reports");
    } catch (error) {
      message.error("Failed to share report.");
    }
  };

  const handleShowPreview = () => {
    navigate("/admin/share/report/preview", {
      state: { reportData, instituteInfo, instituteUserInfo },
    });
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" align="center" gutterBottom>
        Edit Report Details
      </Typography>
      <Paper sx={{ padding: 3, margin: "0 auto" }}>
        <TextField
          fullWidth
          label="Exam Name"
          name="examName"
          value={reportData.examName || ""}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Student Email"
          name="userEmail"
          value={reportData.userEmail || ""}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Total Marks"
          name="totalMarks"
          value={reportData.totalMarks || ""}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Obtained Marks"
          name="obtainedMarks"
          value={reportData.result?.obtainedMarks || ""}
          onChange={(e) => handleNestedChange("obtainedMarks", e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Verdict"
          name="verdict"
          value={reportData.result?.verdict || ""}
          onChange={(e) => handleNestedChange("verdict", e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Course / Class Name"
          name="courseName"
          value={reportData.courseName}
          onChange={handleChange}
          margin="normal"
          disabled
        />
        {/* Dropdown for Course/Class */}
        <Select
          fullWidth
          name="courseClass"
          value={reportData.courseClass || ""}
          onChange={handleChange}
          displayEmpty
          sx={{ mt: 2, mb: 2 }}
          required
        >
          {/* Example options, replace with actual course/class options */}
          <MenuItem value="">Select Name</MenuItem>
          <MenuItem value="Course">Course</MenuItem>
          <MenuItem value="Class">Class</MenuItem>
        </Select>
        <TextField
          fullWidth
          label="Academic Session"
          name="academicSession"
          value={reportData.academicSession}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Current Date"
          name="date"
          value={reportData.date}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Class Teacher Name"
          name="classSignature"
          value={reportData.classSignature}
          onChange={handleChange}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Principal Name"
          name="principalSignature"
          value={reportData.principalSignature}
          onChange={handleChange}
          margin="normal"
          required
        />

        <Button
          onClick={handleShareReport}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
        >
          Share Report
        </Button>
        <Button
          onClick={handleShowPreview}
          variant="outlined"
          sx={{ ml: 2, mt: 3 }}
        >
          Show Preview
        </Button>
      </Paper>
    </Box>
  );
};

export default ShareReport;
