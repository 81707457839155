// import React, { useState, useEffect } from "react";
// import AllUsers from "./AllUsers";
// import { PieChart } from "@mui/x-charts/PieChart";
// import { Container, Typography, Box, Grid } from "@mui/material/";
// import { message } from "antd";
// import axios from "../axiosInstance";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import Stack from "@mui/material/Stack";
// import { BarChart } from "@mui/x-charts/BarChart";
// import Paper from "@mui/material/Paper";
// import PageTitle from '../Components/PageTitle/PageTitle';
// import VendorProfileViewPlan from './VendorProfileViewPlan';

// const Dashboard = () => {
//   const [exams, setExams] = useState([]);
//   const [question, setQuestion] = useState([]);
//   const [reports, setReports] = useState([]);
//   const [users, setUsers] = useState([]);
//   const [vendors, setVendors] = useState([]);
//   const [contact, setContact] = useState([]);
//   const [subjectiveExams, setSubjectiveExams] = useState([]);
//   const [subjectiveQuestions, setSubjectiveQuestions] = useState([]);
//   const [subjectiveReports, setSubjectiveReports] = useState([]);

//   // get-all-exams
//   const displayExams = async () => {
//     try {
//       const data = await axios.post("/api/v1/exams/admin-get-all-exams");
//       setExams(data?.data?.data);
//     } catch (error) {
//       message.error(error?.response?.data?.message)
//     }
//   };
// // Get All Subjective Exam
//   const displaySubjectiveExams = async () => {
//     try {
//       const data = await axios.post("/api/v1/subjective/admin-get-all-exams-subjective");
//       setSubjectiveExams(data?.data?.data);
//     } catch (error) {
//       message.error(error?.response?.data?.message)
//     }
//   };

//   // Get All Subjective Question
//   const displayGetAllSubjectiveQuestion = async () => {
//     try {
//       const response = await axios.get(`/api/v1/question/subjective/admin-get-all-subjectiveQuestions`);
//       setSubjectiveQuestions(response?.data?.data);
//     } catch (error) {
//       message.error(error?.response?.data?.message);
//     }
//   };

//   // get-all-questions
//   const displayQuestions = async () => {
//     try {
//       const data = await axios.post("/api/v1/question/admin-get-dashboard-questions");
//       setQuestion(data?.data?.data);
//     } catch (error) {
//       message.error(error?.response?.data?.message)
//     }
//   };

//   // Get all reports
//   const displayReports = async () => {
//     try {
//       const response = await axios.post(`/api/v1/reports/admin-get-all-reports`);
//       setReports(response?.data?.data);
//     } catch (error) {
//       message.error(error?.response?.data?.message)
//     }
//   };
//     // Get all reports
//     const displaySubjectiveReports = async () => {
//       try {
//         const response = await axios.post(`/api/v1/reports/subjective/admin-get-all-Reports-subjectiveReport`);
//         setSubjectiveReports(response?.data?.data);
//       } catch (error) {
//         message.error(error?.response?.data?.message)
//       }
//     };

//   // get-all-users
//   const displayUsers = async () => {
//     try {
//       const { data } = await axios.get("/api/v1/auth/admin-get-all-user");
//       setUsers(data?.user);
//     } catch (error) {
//       message.error(error?.response?.data?.message)
//     }
//   };

//    // get-all-vendor
//    const displayVendor = async () => {
//     try {
//       const { data } = await axios.get("/api/v1/common/admin-get-vendor");
//       setVendors(data?.vendors);
//     } catch (error) {
//       message.error(error?.response?.data?.message)
//     }
//   };

//   // get-all-contact
//   const displayContact = async () => {
//     try {
//       const { data } = await axios.get("/api/v1/contact/admin-get-contact");
//       setContact(data?.contactInfo);
//     } catch (error) {
//       message.error(error?.response?.data?.message)
//     }
//   };

//   useEffect(() => {
//     const intervalId = setInterval(() => {}, 1000);
//     displayExams();
//     displayQuestions();
//     displayReports();
//     displayUsers();
//     displayVendor()
//     displayContact();
//     displaySubjectiveExams();
//     displayGetAllSubjectiveQuestion();
//     displaySubjectiveReports();
//     return () => clearInterval(intervalId);
//   }, []);

//   return (
//     <>
//     <PageTitle title="Admin Dashboard | Test Series" />
//       <>
//         <Box sx={{ flexGrow: 1, mr:5 }}>
//           <Stack spacing={2} >
//             <Grid container spacing={2} >
//               <Grid item xs={12} sm={4} >
//                 <Paper elevation={12}>
//                   <Card
//                     variant="outlined"
//                     style={{ background: "rgb(2, 178, 175)", color: "#fff" }}
//                   >
//                     <CardContent>
//                       <h3 style={{ fontWeight: "bold" }}>Exams </h3>
//                       <h6 style={{ fontSize: "30px" }}>{exams?.length + subjectiveExams?.length}</h6>
//                     </CardContent>
//                   </Card>
//                 </Paper>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <Paper elevation={12}>
//                   <Card
//                     variant="outlined"
//                     style={{ background: "rgb(46, 150, 255)", color: "#fff" }}
//                   >
//                     <CardContent>
//                       <h3 style={{ fontWeight: "bold" }}>Questions </h3>
//                       <h6 style={{ fontSize: "30px" }}>{question?.length+subjectiveQuestions?.length}</h6>
//                     </CardContent>
//                   </Card>
//                 </Paper>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <Paper elevation={12}>
//                   <Card
//                     variant="outlined"
//                     style={{ background: "rgb(184, 0, 216)", color: "#fff" }}
//                   >
//                     <CardContent>
//                       <h3 style={{ fontWeight: "bold" }}>Reports </h3>
//                       <h6 style={{ fontSize: "30px" }}>{reports?.length+subjectiveReports?.length}</h6>
//                     </CardContent>
//                   </Card>
//                 </Paper>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <Paper elevation={12}>
//                   <Card
//                     variant="outlined"
//                     style={{ background: "rgb(96, 0, 155)", color: "#fff" }}
//                   >
//                     <CardContent>
//                       <h3 style={{ fontWeight: "bold" }}>Students </h3>
//                       <h6 style={{ fontSize: "30px" }}>{users?.length}</h6>
//                     </CardContent>
//                   </Card>
//                 </Paper>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <Paper elevation={12}>
//                   <Card
//                     variant="outlined"
//                     style={{ background: "rgb(39, 49, 200)", color: "#fff" }}
//                   >
//                     <CardContent>
//                       <h3 style={{ fontWeight: "bold" }}>Accounts </h3>
//                       <h6 style={{ fontSize: "30px" }}>{vendors?.length}</h6>
//                     </CardContent>
//                   </Card>
//                 </Paper>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <Paper elevation={12}>
//                   <Card
//                     variant="outlined"
//                     style={{ background: "rgb(3, 0, 141)", color: "#fff" }}
//                   >
//                     <CardContent>
//                       <h3 style={{ fontWeight: "bold" }}>Contacts </h3>
//                       <h6 style={{ fontSize: "30px" }}>{contact?.length}</h6>
//                     </CardContent>
//                   </Card>
//                 </Paper>
//               </Grid>
//             </Grid>
//           </Stack>
//         </Box>
//         <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3, m:3 }}>
//           Data Chart
//         </Typography>
//       </>
//       <Box>
//         <Grid
//           container
//           spacing={{ xs: 3, md: 2 }}
//           columns={{ xs: 12, sm: 12, md: 12 }}
//         >
//           <Grid item xs={12} sm={12} md={5.5}>
//             <PieChart
//               series={[
//                 {
//                   data: [
//                     { id: 0, value: exams?.length, label: "Exams A" },
//                     { id: 1, value: question?.length, label: " Questions B" },
//                     { id: 2, value: reports?.length, label: "Reports C" },
//                     { id: 3, value: users?.length, label: "Students D" },
//                     { id: 4, value: vendors?.length, label: "Accounts E" },
//                     { id: 5, value: contact?.length, label: "Contacts F" },
//                   ],
//                 },
//               ]}
//               height={200}
//             />
//           </Grid>
//           <Grid item xs={12} sm={12} md={5.3}>
//             <BarChart
//               series={[
//                 { data: [exams?.length] },
//                 { data: [question?.length] },
//                 { data: [reports?.length] },
//                 { data: [users?.length] },
//                 { data: [vendors?.length] },
//                 { data: [contact?.length] },
//               ]}
//               height={290}
//               xAxis={[{ scaleType: "band", data: ["Data Chart"] }]}
//               margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
//             />
//           </Grid>
//         </Grid>
//       </Box>
//       <Box sx={{m:3}}>
//         <AllUsers />{" "}
//       </Box>
//     </>
//   );
// };

// export default Dashboard;

import React, { useState, useEffect } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { Typography, Box, Grid } from "@mui/material/";
import { message } from "antd";
import axios from "../axiosInstance";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { BarChart } from "@mui/x-charts/BarChart";
import Paper from "@mui/material/Paper";
import PageTitle from "../Components/PageTitle/PageTitle";

const Dashboard = () => {
  const [exams, setExams] = useState([]);
  const [question, setQuestion] = useState([]);
  const [reports, setReports] = useState([]);
  const [users, setUsers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [contact, setContact] = useState([]);
  const [subjectiveExams, setSubjectiveExams] = useState([]);
  const [subjectiveQuestions, setSubjectiveQuestions] = useState([]);
  const [subjectiveReports, setSubjectiveReports] = useState([]);

  const displayExams = async () => {
    try {
      const data = await axios.post("/api/v1/exams/admin-get-all-exams");
      setExams(data?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const displaySubjectiveExams = async () => {
    try {
      const data = await axios.post(
        "/api/v1/subjective/admin-get-all-exams-subjective"
      );
      setSubjectiveExams(data?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const displayGetAllSubjectiveQuestion = async () => {
    try {
      const response = await axios.get(
        `/api/v1/question/subjective/admin-get-all-subjectiveQuestions`
      );
      setSubjectiveQuestions(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const displayQuestions = async () => {
    try {
      const data = await axios.post(
        "/api/v1/question/admin-get-dashboard-questions"
      );
      setQuestion(data?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const displayReports = async () => {
    try {
      const response = await axios.post(
        `/api/v1/reports/admin-get-all-reports`
      );
      setReports(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const displaySubjectiveReports = async () => {
    try {
      const response = await axios.post(
        `/api/v1/reports/subjective/admin-get-all-Reports-subjectiveReport`
      );
      setSubjectiveReports(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const displayUsers = async () => {
    try {
      const response = await axios.get(
        `/api/v1/batch/admin-get-institute-all-user-batch`
      );
      if (response?.data?.success === true) {
        setUsers(response?.data?.user);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const displayVendor = async () => {
    try {
      const { data } = await axios.get("/api/v1/common/admin-get-vendor");
      setVendors(data?.vendors);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const displayContact = async () => {
    try {
      const { data } = await axios.get("/api/v1/contact/admin-get-contact");
      setContact(data?.contactInfo);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    displayExams();
    displayQuestions();
    displayReports();
    displayUsers();
    displayVendor();
    displayContact();
    displaySubjectiveExams();
    displayGetAllSubjectiveQuestion();
    displaySubjectiveReports();
  }, []);

  return (
    <>
      <PageTitle title="Admin Dashboard | Test Series" />
      <Box>
        <Box sx={{ mt:1, ml: 2, mr: 2 }}>
          <Grid container spacing={4}>
            {[
              //  {
              //   title: "Accounts",
              //   value: vendors.length,
              //   color: "rgb(102, 12, 104)",
              // },
              // {
              //   title: "Batch",
              //   value: vendors.length,
              //   color: "rgb(203, 102, 14)",
              // },
              // {
              //   title: "Student",
              //   value: contact.length,
              //   color: "rgb(198, 107, 205)",
              //   fontWeight: "bold",
              // },
              {
                title: "Exams",
                value: exams.length + subjectiveExams.length,
                // color: "rgb(0, 123, 255)",
                // color: "#2d42a8",
                color: "rgb(2, 178, 175)"
              },
              {
                title: "Questions",
                value: question.length + subjectiveQuestions.length,
                // color: "rgb(184, 193, 7)",
                color: "rgb(46, 150, 255)",
              },
              {
                title: "Reports",
                value: reports.length + subjectiveReports.length,
                // color: "rgb(40, 167, 69)",
                color: "rgb(184, 0, 216)",
              },
              {
                title: "Students",
                value: users.length,
                color: "rgb(96, 0, 155)",
                // color: "rgb(184, 0, 69)",
              },
              {
                title: "Accounts",
                value: vendors.length,
                // color: "rgb(23, 162, 184)",
                color: "rgb(3, 0, 141)",
              },
              {
                title: "Contacts",
                value: contact.length,
                fontWeight: "bold",
                // color: "rgb(108, 117, 125)",
                color: "rgb(3, 0, 141)",
              },
             
            ].map((card, idx) => (
              <Grid item xs={12} sm={6} md={4} key={idx}>
                <Box
                  variant="h5"
                  // sx={{
                  //   backgroundColor: card.color,
                  //   borderRadius: "15px !important",
                  //   padding: "20px",
                  // }}
                  //  color:"#000"
                >
                  <Card
                    sx={{
                      transition: "0.3s !important",
                      "&:hover": { transform: "scale(1.05)" },
                      borderRadius: "15px",
                      boxShadow:
                        "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                      padding: "15px",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      // backgroundColor: "#FFF",
                      backgroundColor: card.color,
                      
                    }}
                  >
                    {" "}
                    <CardContent>
                      <Typography variant="h5" component="div" color="#fff">
                        {" "}
                        {card.title}
                      </Typography>
                      <Typography
                        variant="h4"
                        component="div"
                        color="#fff"
                        sx={{ fontWeight: "bold", pt: 1 }}
                      >
                        {card.value}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Typography
            variant="h5"
            className="courses_title"
            gutterBottom
            sx={{
              mt: 5,
              mb: 5,
              letterSpacing: "2px",
              lineHeight: 1.8,
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
              color: "#2C3E50",
            }}
          >
            Data Visualization
          </Typography>

          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Paper elevation={5} sx={{ p: 2 }}>
                <PieChart
                  series={[
                    {
                      data: [
                        { id: 0, value: exams.length, label: "Exams" },
                        { id: 1, value: question.length, label: "Questions" },
                        { id: 2, value: reports.length, label: "Reports" },
                        { id: 3, value: users.length, label: "Students" },
                        { id: 4, value: vendors.length, label: "Accounts" },
                        { id: 5, value: contact.length, label: "Contacts" },
                      ],
                    },
                  ]}
                  height={250}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={5} sx={{ p: 2 }}>
                <BarChart
                  series={[
                    { data: [exams.length] },
                    { data: [question.length] },
                    { data: [reports.length] },
                    { data: [users.length] },
                    { data: [vendors.length] },
                    { data: [contact.length] },
                  ]}
                  height={250}
                  xAxis={[{ scaleType: "band", data: ["Data"] }]}
                  margin={{ top: 10, bottom: 40, left: 50, right: 10 }}
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
