import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Container,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search"; // Import the Search icon
import { message } from "antd";
import axios from "../../axiosInstance";
import TestSeriesLogo from "../../assets/TS_Logo.png";
import InstituteFacilities from "./InstituteFacilities";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: "30px",
    height: "auto",
    // marginLeft: "25px",
    // marginRight: "25px",
  },
  card: {
    // width: 300,
    borderRadius: "20px !important",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1) !important",
    marginTop: "20px",
    marginBottom: "20px",
    transition: "0.3s !important",
    "&:hover": { transform: "scale(1.05)" },
  },
  cardHeader: {
    height: 160,
    width: 200,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    justifyItems: "center",
    backgroundSize: "cover",
    WebkitBackgroundOrigin: "border-box",
  },
  nextClass: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    background: "#1976d2 !important",
    color: "white !important",
    border: "none",
    borderRadius: "24px !important",
    padding: "10px 24px",
    fontSize: 16,
    cursor: "pointer",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    transition: "background 0.3s ease-in-out, transform 0.2s ease-in-out",
  },
  nextClassHover: {
    background: "linear-gradient(90deg, #1976d2 0%, #000 100%)",
    transform: "scale(1.05)",
  },
});

const AllInstitutes = () => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [allInstitute, setAllInstitute] = useState([]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const enrollInstitute = (e, instituteName, instituteId, _id) => {
    e.preventDefault();
    const formattedInstituteName = instituteName
      .replace(/\s+/g, "-")
      .replace(/&/g, "and");
    navigate(`/testseries/institute/${formattedInstituteName}`, {
      state: { instituteId, _id },
    });
  };

  // Get All Institute Information
  const getAllInstitute = async () => {
    try {
      const response = await axios.get(`/api/v1/demo/get-all-institute`);
      setAllInstitute(response?.data.institute || []);
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Error fetching institutes"
      );
    }
  };

  useEffect(() => {
    getAllInstitute();
  }, []);

  const filteredInstitutes = allInstitute.filter((institute) =>
    institute.instituteName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const StudentInstituteCard = ({
    _id,
    image,
    instituteName,
    description,
    nextClass,
    instituteId,
    instituteLogo,
  }) => {
    const classes = useStyles();
    return (
      <>
        <Card
          className={classes.card}
          // sx={{ width: { md: 300, sm: "100%", xs: "100%" }}}
          sx={{
            width: { md: 450, sm: "100%", xs: "100%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 160,
              // width: 200,
              marginTop: 10,
              overflow: "hidden",
            }}
          >
            <img
              src={instituteLogo || TestSeriesLogo}
              // src="https://res.cloudinary.com/dnzuoib2c/image/upload/v1722937662/vendors/roduhk0p8mlhp6n3e5ih.png"
              alt="Institute Logo"
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
          <CardContent>
            <Typography
              variant="h6"
              sx={{
                letterSpacing: ".1rem",
                fontSize: "16px",
                marginBottom: "12px",
                fontWeight: "bold",
                fontFamily: "Poppins, sans-serif",
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
                color: "#2C3E50",
                // color: "#1E88E5",
                alignItems: "center",
              }}
            >
              {instituteName}
            </Typography>
            <Typography
              className="courses_desc"
              variant="body2"
              color="text.secondary"
              sx={{
                mt: 1,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                fontSize: "15px",
                letterSpacing: ".1rem",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {description}
            </Typography>

            <Button
              variant="outlined"
              color="primary"
              fullWidth
              sx={{
                mt: 2,
                borderRadius: "50px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
              onClick={(e) =>
                enrollInstitute(e, instituteName, instituteId, _id)
              }
            >
              More Details
            </Button>
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <Box sx={{ bgcolor: "#f5f5f5", py: 2 }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          mb: 2,
          fontWeight: "bold",
          color: theme.palette.primary.main,
          textShadow: "1px 1px 3px rgba(0, 0, 0, 0.1)",
        }}
      >
        All Institutes
      </Typography>
      <Container sx={{ pb: 2, display: "flex", justifyContent: "center" }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by Institute Name"
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#1976d2" }} />
              </InputAdornment>
            ),
          }}
          sx={{
            bgcolor: "white",
            borderRadius: "20px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
              "&:hover fieldset": {
                border: "none",
              },
              "&.Mui-focused fieldset": {
                border: "none",
              },
            },
          }}
        />
      </Container>
      <Box
        sx={{
          backgroundColor: "#f0f4f8",
          borderRadius: "30px",
          // boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Section for Your Institute */}
        <Box
          className={classes.root}
          sx={{
            mb: 2,
            mt: 2,
            ml: { md: 5, xs: 2, sm: 2 },
            mr: { md: 5, xs: 2, sm: 2 },
          }}
        >
          {filteredInstitutes.length > 0 ? (
            filteredInstitutes
              .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
              .map((course, i) => <StudentInstituteCard key={i} {...course} />)
          ) : (
            <Typography
              variant="h6"
              align="center"
              sx={{
                width: "100%",
                color: "#2C3E50",
                fontFamily: "Poppins, sans-serif",
                mt: 2,
              }}
            >
              No institutes found for "{searchQuery}".
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AllInstitutes;
