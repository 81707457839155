// import React, { useState, useEffect } from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Box,
// } from "@mui/material";
// import { message } from "antd";
// import { Typography } from "@mui/material";
// import axios from "../axiosInstance";
// import Navbar from "../Components/Navbar";
// import PageTitle from "../Components/PageTitle/PageTitle";
// import Footer from "../Components/Footer";
// import useInstituteUser from "../Components/GetInstituteUser";

// function TopRank() {
//   const [mergedExamData, setMergedExamData] = useState([]);
//   const studentCourse = useInstituteUser();

//   const [coursesMap, setCoursesMap] = useState({});

//   // Fetch all institute courses
//   const studentCourseData = () => {
//     if (studentCourse) {
//       const map = {};
//       studentCourse.forEach((course) => {
//         map[course.studentCourseId] = course.studentCourseName;
//       });
//       setCoursesMap(map);
//     }
//   };

//   useEffect(() => {
//     if (studentCourse) {
//       studentCourseData(studentCourse);
//     }
//   }, [studentCourse]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const [objectiveRes, subjectiveRes] = await Promise.all([
//           axios.post("/api/v1/reports/get-top-rank", { studentCourse }),
//           axios.post(
//             "/api/v1/reports/subjective/get-top-rank-subjectiveReport",
//             { studentCourse }
//           ),
//         ]);
//         const objectiveData = objectiveRes.data?.maxCorrectAnswersPerExam
//           ? Object.keys(objectiveRes.data.maxCorrectAnswersPerExam).map(
//               (examId) => {
//                 const exam = objectiveRes.data.maxCorrectAnswersPerExam[examId];
//                 return {
//                   ...exam,
//                   examType: "Objective",
//                   obtainedMarks: exam.result?.obtainedMarks,
//                   totalMarks: exam?.totalMarks,
//                   instituteCourseId: exam?.instituteCourseId,
//                 };
//               }
//             )
//           : [];

//         const subjectiveData = subjectiveRes.data?.topRankData
//           ? Object.keys(subjectiveRes.data.topRankData).map((examId) => {
//               const exam = subjectiveRes.data.topRankData[examId];
//               return {
//                 ...exam,
//                 examType: "Subjective",
//                 obtainedMarks: exam.totalMarksObtained,
//                 totalMarks: exam?.totalMarks,
//                 instituteCourseId: exam?.instituteCourseId,
//               };
//             })
//           : [];

//         // Merge and sort data
//         const combinedData = [...objectiveData, ...subjectiveData];
//         const sortedData = combinedData.sort(
//           (a, b) => b.obtainedMarks - a.obtainedMarks
//         );

//         setMergedExamData(sortedData);
//       } catch (error) {
//         message.error(error?.response?.data?.message);
//       }
//     };

//     fetchData();
//     if (studentCourse) {
//       fetchData(studentCourse);
//     }
//   }, [studentCourse]);

//   return (
//     <>
//       <Navbar />
//       <PageTitle title="Top Rank List | Test Series" />
//       <Box
//         sx={{
//           mb: 4,
//           pt: 3,
//           pl: { xs: 2, sm: 2, md: 12, lg: 14 },
//           pr: { xs: 2, sm: 2, md: 12, lg: 14 },
//           mt: { xs: 12, sm: 12, md: 12, lg: 14 },
//           minHeight: "100vh",
//         }}
//       >
//         <Typography
//           gutterBottom
//           variant="h4"
//           component="div"
//           color="text.secondary"
//           sx={{
//             pb: 3,
//             textAlign: "center",
//             letterSpacing: "2.5px",
//             lineHeight: 1.8,
//             fontSize: "30px",
//             fontFamily: "Poppins, sans-serif",
//             fontWeight: "bold",
//             color: "#555",
//           }}
//         >
//           Your Performance Rankings
//         </Typography>

//         <TableContainer component={Paper}>
//           <Table>
//             <TableHead
//               sx={{ backgroundColor: "rgb(25, 118, 210)", color: "white" }}
//             >
//               <TableRow>
//                 <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                   Top Rank
//                 </TableCell>
//                 <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                   Student Name
//                 </TableCell>
//                 <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                   Student Email
//                 </TableCell>
//                 <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                   Exam Name
//                 </TableCell>
//                 <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                   Course Name
//                 </TableCell>
//                 <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                   Exam Type
//                 </TableCell>
//                 <TableCell sx={{ color: "white", fontWeight: "bold" }}>
//                   Student Score
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {mergedExamData.length > 0 ? (
//                 mergedExamData.map((exam, index) => (
//                   <TableRow key={index}>
//                     <TableCell>{index + 1}</TableCell>

//                     <TableCell>{exam.userName || "N/A"}</TableCell>
//                     <TableCell>{exam.userEmail || "N/A"}</TableCell>
//                     <TableCell>{exam?.examName || "N/A"}</TableCell>
//                     <TableCell>
//                       {coursesMap[exam.instituteCourseId] || "Unknown Course"}
//                     </TableCell>

//                     <TableCell>{exam.examType}</TableCell>
//                     <TableCell>
//                       {exam.obtainedMarks
//                         ? exam.obtainedMarks.toFixed(2)
//                         : "0.00"}{" "}
//                       / {exam.totalMarks || 0}
//                     </TableCell>
//                   </TableRow>
//                 ))
//               ) : (
//                 <TableRow>
//                   <TableCell colSpan={6} align="center">
//                     No data available
//                   </TableCell>
//                 </TableRow>
//               )}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>
//       <Footer />
//     </>
//   );
// }

// export default TopRank;

import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TablePagination,
} from "@mui/material";
import { message } from "antd";
import { Typography } from "@mui/material";
import axios from "../axiosInstance";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import Footer from "../Components/Footer";
import useInstituteUser from "../Components/GetInstituteUser";

function TopRank() {
  const [mergedExamData, setMergedExamData] = useState([]);
  const studentCourse = useInstituteUser();
  const [coursesMap, setCoursesMap] = useState({});
  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(5); 

  // Fetch all institute courses
  const studentCourseData = () => {
    if (studentCourse) {
      const map = {};
      studentCourse.forEach((course) => {
        map[course.studentCourseId] = course.studentCourseName;
      });
      setCoursesMap(map);
    }
  };

  useEffect(() => {
    if (studentCourse) {
      studentCourseData(studentCourse);
    }
  }, [studentCourse]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [objectiveRes, subjectiveRes] = await Promise.all([
          axios.post("/api/v1/reports/get-top-rank", { studentCourse }),
          axios.post(
            "/api/v1/reports/subjective/get-top-rank-subjectiveReport",
            { studentCourse }
          ),
        ]);
        const objectiveData = objectiveRes.data?.maxCorrectAnswersPerExam
          ? Object.keys(objectiveRes.data.maxCorrectAnswersPerExam).map(
              (examId) => {
                const exam = objectiveRes.data.maxCorrectAnswersPerExam[examId];
                return {
                  ...exam,
                  examType: "Objective",
                  obtainedMarks: exam.result?.obtainedMarks,
                  totalMarks: exam?.totalMarks,
                  instituteCourseId: exam?.instituteCourseId,
                };
              }
            )
          : [];

        const subjectiveData = subjectiveRes.data?.topRankData
          ? Object.keys(subjectiveRes.data.topRankData).map((examId) => {
              const exam = subjectiveRes.data.topRankData[examId];
              return {
                ...exam,
                examType: "Subjective",
                obtainedMarks: exam.totalMarksObtained,
                totalMarks: exam?.totalMarks,
                instituteCourseId: exam?.instituteCourseId,
              };
            })
          : [];

        // Merge and sort data
        const combinedData = [...objectiveData, ...subjectiveData];
        const sortedData = combinedData.sort(
          (a, b) => b.obtainedMarks - a.obtainedMarks
        );

        setMergedExamData(sortedData);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    };

    fetchData();
    if (studentCourse) {
      fetchData(studentCourse);
    }
  }, [studentCourse]);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Get current rows to display based on pagination
  const currentRows = mergedExamData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <Navbar />
      <PageTitle title="Top Rank List | Test Series" />
      <Box
        sx={{
          mb: 4,
          pt: 3,
          pl: { xs: 2, sm: 2, md: 12, lg: 14 },
          pr: { xs: 2, sm: 2, md: 12, lg: 14 },
          mt: { xs: 12, sm: 12, md: 12, lg: 14 },
          minHeight: "100vh",
        }}
      >
        <Typography
          gutterBottom
          variant="h4"
          component="div"
          color="text.secondary"
          sx={{
            pb: 3,
            textAlign: "center",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
            fontSize: "30px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "bold",
            color: "#555",
          }}
        >
          Your Performance Rankings
        </Typography>

        <TableContainer component={Paper}>
          <Table>
            <TableHead
              sx={{ backgroundColor: "rgb(25, 118, 210)", color: "white" }}
            >
              <TableRow>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Top Rank
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Student Name
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Student Email
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Exam Name
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Course Name
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Exam Type
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Student Score
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentRows.length > 0 ? (
                currentRows.map((exam, index) => (
                  <TableRow key={index}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{exam.userName || "N/A"}</TableCell>
                    <TableCell>{exam.userEmail || "N/A"}</TableCell>
                    <TableCell>{exam?.examName || "N/A"}</TableCell>
                    <TableCell>
                      {coursesMap[exam.instituteCourseId] || "Unknown Course"}
                    </TableCell>
                    <TableCell>{exam.examType}</TableCell>
                    <TableCell>
                      {exam.obtainedMarks
                        ? exam.obtainedMarks.toFixed(2)
                        : "0.00"}{" "}
                      / {exam.totalMarks || 0}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={mergedExamData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
      <Footer />
    </>
  );
}

export default TopRank;

