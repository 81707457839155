import React, { useState, useEffect } from "react";
import { message, Modal } from "antd";
import { Container, Box, TextField, Button } from "@mui/material";
import axios from "../../axiosInstance";
import { useSelector } from "react-redux";

const EditSubjectiveQuestionModal = ({
  editSubjectiveQuestionModal,
  setEditSubjectiveQuestionModal,
  refreshData,
  examId,
  selectedQuestion,
  setSelectedQuestion,
}) => {
  const [name, setName] = useState("");
  const [referenceAnswer, setReferenceAnswer] = useState("");
  const [questionNumber, setQuestionNumber] = useState("");
  const [instituteId, setInstituteId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const id = selectedQuestion?._id;
  const { user } = useSelector((state) => state.user);

  // Get Exam By Subjective Question ID
  const getExamsBySubjectiveQuestionID = async () => {
    try {
      const response = await axios.post(
        `/api/v1/question/subjective/admin-get-exam-to-subjectiveQuestions`,
        { id }
      );
      setName(response?.data?.data?.name);
      setReferenceAnswer(response?.data?.data?.referenceAnswer);
      setQuestionNumber(response?.data?.data?.questionNumber);
      setInstituteId(response?.data?.data?.instituteId);
      setVendorId(response?.data?.data?.vendorId);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getExamsBySubjectiveQuestionID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Subjective Question Edit Api Call
const handleSubmit = async (e) => {
  e.preventDefault();
  // Show confirmation modal before submission
  Modal.confirm({
    title: "Are you sure you want to Edit this Subjective Question?",
    content:
      "Please verify that all the details are correct before updating.",
    okText: "Yes, Edit",
    cancelText: "No, Cancel",
    onOk: async () => {
      const required = {
        name,
        referenceAnswer,
        questionNumber,
        questionId: id,
        exam: examId,
        id: id,
        instituteId: instituteId,
        vendorId: vendorId,
      };

      try {
        const response = await axios.put(
          "/api/v1/question/subjective/admin-update-exam-to-subjectiveQuestions",
          required
        );
        if (response.data.success === true) {
          message.success(response?.data?.message);
          refreshData();
          setEditSubjectiveQuestionModal(false);
        } else {
          message.error(response?.data?.message);
        }
        setSelectedQuestion(null);
      } catch (error) {
        message.error(error?.response?.data?.message || "An error occurred");
      }
    },
    onCancel() {
      console.log("Edit canceled");
    },
  });
};


  return (
    <div>
      <Modal
        title={selectedQuestion ? "Edit Subjective Question" : "Add Question"}
        visible={editSubjectiveQuestionModal}
        footer={false}
        onCancel={() => {
          setEditSubjectiveQuestionModal(false);
          setSelectedQuestion(null);
        }}
        width={1000}
      >
        <Container
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
          maxWidth
        >
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="question"
            label="Question Name"
            name="question"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Question Name"
            value={name}
            multiline
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              "& input[type='number']": {
                MozAppearance: "textfield",
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  display: "none",
                },
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="questionNumber"
            label="Question Number"
            name="questionNumber"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            type="Number"
            placeholder="Question Number"
            value={questionNumber}
            onChange={(e) => setQuestionNumber(e.target.value)}
          />
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="referenceAnswer"
            label="Reference Answer"
            name="referenceAnswer"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Reference Answer"
            value={referenceAnswer}
            multiline
            onChange={(e) => setReferenceAnswer(e.target.value)}
          />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            className="courses_desc"
            sx={{
              mt: 3,
              borderRadius: "5px",
              textTransform: "none",
              fontFamily: "Poppins, sans-serif",
              letterSpacing: ".1rem",
            }}
          >
            Edit Subjective Question
          </Button>
        </Container>
      </Modal>
    </div>
  );
};

export default EditSubjectiveQuestionModal;
