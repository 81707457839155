import React, { useState, useEffect, useRef } from "react";
import axios from "../../axiosInstance";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Paper,
  Button,
  Container,
} from "@mui/material";
import { message } from "antd";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const EvaluationDetails = () => {
  const { id } = useParams();
  const theme = useTheme();
  const [updateReport, setUpdateReport] = useState([]);
  const [report, setReport] = useState([]);
  const [marksObtained, setMarksObtained] = useState(0);
  const [marks, setMarks] = useState({});
  const containerRef = useRef(null);

  // Fetch all subjective exams for a given exam ID
  const getAllSubjectiveExams = async () => {
    try {
      const response = await axios.post(
        "/api/v1/reports/subjective/admin-get-exam-by-subjectiveReport",
        { reportId: id }
      );
      if (response?.data?.success === true) {
        const exams = response?.data?.data;
        setReport(exams);
        // Retrieve marks from local storage for this user and exam
        const storedMarks =
          JSON.parse(localStorage.getItem(`marks_${exams[0].userId}_${id}`)) ||
          {};
        setMarks(storedMarks);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllSubjectiveExams();
  }, [id]);

  useEffect(() => {
    // Calculate total marks and marks obtained whenever marks or report change
    if (report.length > 0) {
      const totalMarks = Object.values(marks).reduce(
        (sum, mark) => sum + parseFloat(mark || 0),
        0
      );
      const negativeMarks = report[0]?.answersData.reduce(
        (sum, answer, index) => {
          const awardedMarks = parseFloat(marks[index] || 0);
          return (
            sum +
            (awardedMarks < answer.questionNumber
              ? awardedMarks * report[0].negativeMarking
              : 0)
          );
        },
        0
      );
      setMarksObtained((totalMarks - negativeMarks).toFixed(2));
    }
  }, [marks, report]);

  const handleMarksChange = (index, value) => {
    // Check if the entered value is greater than the question number
    const maxMarks = report[0]?.answersData[index]?.questionNumber || 0;
    if (parseFloat(value) > maxMarks) {
      message.error(`Marks awarded cannot be greater than ${maxMarks}`);
      return;
    }

    const updatedMarks = {
      ...marks,
      [index]: value,
    };

    // Only update if the question has an answer
    if (report[0]?.answersData[index]) {
      setMarks(updatedMarks);
      // Save updated marks to local storage
      localStorage.setItem(
        `marks_${report[0].userId}_${id}`,
        JSON.stringify(updatedMarks)
      );
    } else {
      message.error(
        `Cannot add marks for this question as there is no answer.`
      );
    }
  };

  // Update the Student Reports
  const handleSave = async () => {
    const verdict = marksObtained >= report[0].passingMarks ? "PASS" : "FAIL";
    const updatedData = {
      ...report[0],
      answersData: report[0].answersData.map((answer, index) => ({
        ...answer,
        marks: marks[index] || 0,
      })),
      totalMarksObtained: marksObtained,
      verdict: verdict,
      reportId: id,
    };
    try {
      const response = await axios.post(
        "/api/v1/reports/subjective/admin-update-subjectiveReport",
        updatedData
      );
      message.success("Report updated successfully!");
      setReport([response.data.data]);
      // Clear local storage for marks of the current exam and user
      localStorage.removeItem(`marks_${report[0].userId}_${id}`);
      getAllSubjectiveExams();
    } catch (error) {
      message.error("Failed to update report");
    }
  };

  if (report.length === 0) {
    return null;
  }

  const exam = report[0];

  return (
    <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          mb: 3,
          fontFamily: "Poppins, sans-serif",
          color: "#2C3E50",
          letterSpacing: "2.5px",
          lineHeight: 1.8,
        }}
      >
        Students Copy with his Answers
      </Typography>
      <hr />

      <Box ref={containerRef} mb={5} mt={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">
              {" "}
              <b>Student Name: </b>
              {exam.userName}
            </Typography>
            <Typography variant="h6">
              <b>Exam Name</b>: {exam.examName}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              [theme.breakpoints.up("md")]: {
                display: "flex",
                justifyContent: "end",
                flexDirection: "column",
                alignItems: "flex-end",
              },
            }}
          >
            <Typography variant="h6">
              <b>Total Marks:</b> {exam.totalMarks}
            </Typography>
            <Typography variant="h6">
              <b>Passing Marks:</b> {exam.passingMarks}
            </Typography>
            {exam && exam?.negativeMarking?.length > 0 ? (
              <Typography variant="h6">
                <b>Negative Marking: </b> {exam.negativeMarking}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </Box>

      {exam.answersData.map((answer, index) => (
        <Box key={index} mb={2}>
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="body1"
              fontWeight="bold"
              sx={{ marginBottom: 2 }}
            >
              Q{index + 1}: {answer.question}
            </Typography>
            <Grid item display="flex" alignItems="center">
              <TextField
                value={answer.questionNumber}
                variant="outlined"
                size="small"
                sx={{ width: "70px", marginLeft: 1, mb: 2 }}
              />
            </Grid>
          </Grid>
          <TextField
            label="Student Answer"
            multiline
            fullWidth
            defaultValue={answer.answer}
            variant="outlined"
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Reference Answer"
            multiline
            fullWidth
            defaultValue={answer.referenceAnswer}
            variant="outlined"
            sx={{ marginBottom: 2, mt: 2 }}
          />
          {answer.answer && ( // Only render "Marks Awarded" if answer.answer exists
            <Grid container justifyContent="flex-end" alignItems="end">
              <Typography>Marks Awarded</Typography>
              <TextField
                value={marks[index] || ""}
                onChange={(e) => handleMarksChange(index, e.target.value)}
                variant="outlined"
                size="small"
                sx={{ width: "70px", marginLeft: 1, mt: 3 }}
              />
            </Grid>
          )}
        </Box>
      ))}

      <Grid container justifyContent="flex-end" alignItems="end" mb={2}>
        <Typography variant="h6">Marks Obtained</Typography>
        <TextField
          value={marksObtained}
          disabled
          variant="outlined"
          size="small"
          sx={{ width: "70px", marginLeft: 1 }}
        />
      </Grid>

      <Grid container justifyContent="flex-end" alignItems="end" mb={2}>
        <Typography variant="h6">Verdict</Typography>
        <TextField
          value={marksObtained >= exam.passingMarks ? "PASS" : "FAIL"}
          disabled
          variant="outlined"
          size="small"
          sx={{ width: "70px", marginLeft: 1 }}
        />
      </Grid>

      <Grid container justifyContent="flex-end" sx={{ mt: 5 }}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Grid>
    </Paper>
  );
};

export default EvaluationDetails;
