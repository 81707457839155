import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import axios from "../../axiosInstance";
import { useParams } from "react-router-dom";
import Papa from "papaparse";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { message } from "antd";
import DownloadCSV from "./DownloadCSV";
import QuestionPaperPDF from "./QuestionPaperPDF";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";

const AddQuestions = ({ vendor_Id, institute_Id }) => {
  const fileInputRef = useRef(null);
  const { user } = useSelector((state) => state.user);
  const [instituteId, setInstituteId] = useState(institute_Id);
  const [vendorId, setVendorId] = useState(vendor_Id);
  const [csvFile, setCsvFile] = useState(null);
  const [inputType, setInputType] = useState("options");
  const [question, setQuestion] = useState([]);
  const [addQuestionName, setAddQuestionName] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [correctOption, setCorrectOption] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const handleAdd = () => {
    setOptions([...options, "", "", "", ""]);
    setInputType("options");
    setCorrectOption([...correctOption, false]);
  };

  const handleAddMore = () => {
    setOptions([...options, ""]);
    setInputType("options");
    setCorrectOption([...correctOption, false]);
  };

  const handleChangeInputs = (e, index) => {
    const newOptions = [...options];
    newOptions[index] = e.target.value;
    setOptions(newOptions);
  };

  const handleDeleteInputs = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
    if (correctOption === index) {
      setCorrectOption(null);
    } else if (correctOption > index) {
      setCorrectOption(correctOption - 1);
    }
  };

  const handleCheckboxChange = (index) => {
    setCorrectOption((prev) =>
      prev.includes(index)
        ? prev.filter((optionIndex) => optionIndex !== index)
        : [...prev, index]
    );
  };

  // Extracting id from useParams and localStorage
  const { id: paramId } = useParams();
  const localStorageId = JSON.parse(localStorage.getItem("id"));
  const examId = paramId || localStorageId;

  // add Questions in exam
  const handleSubmitQuestion = async (e) => {
    e.preventDefault();
    // Check if questionName, correctOption, and options are filled
    if (addQuestionName.length === 0) {
      message.error("Please enter Question Name");
      return;
    }
    if (options.length === 0) {
      message.error("Please enter Options");
      return;
    }
    if (correctOption.length === 0) {
      message.error("Please enter Correct Options");
      return;
    }
    const filteredCorrectOptions = correctOption.filter(
      (index) => typeof index === "number"
    );
    const questions = {
      name: addQuestionName,
      correctOption: filteredCorrectOptions,
      options,
      exam: examId,
      instituteId: instituteId,
      vendorId: vendorId,
    };
    try {
      const data = await axios.post(
        "/api/v1/question/admin-add-exam-to-questions",
        questions
      );
      if (data.data.success === true) {
        message.success(data.data.message);
        // Clear question form fields
        setAddQuestionName("");
        setCorrectOption([]);
        setOptions([]);
        setOptions(["", "", "", ""]);
      } else {
        message.error(data?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setCsvFile(file);
    handleCsvUpload(file);
  };
  // Upload CSV File
  const handleCsvUpload = (file) => {
    setIsUploading(true);
    setUploadProgress(0);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (parsed) => {
        if (parsed.errors.length > 0) {
          console.error("Parsing error:", parsed.errors);
          setIsUploading(false);
          return;
        }
        if (parsed.data.length > 0) {
          const formattedQuestions = parsed.data.map((row) => {
            const options = [];
            const optionKeys = Object.keys(row).filter((key) =>
              key.startsWith("Option")
            );
            optionKeys.forEach((key) => {
              options.push(row[key].trim().replace(/[.\s]+$/, ""));
            });

            const correctOptions = row["Correct Answer"]
              .split(",")
              .map((answer) => answer.trim().charAt(0).toLowerCase())
              .map((letter) => letter.charCodeAt(0) - 97);

            return {
              name: row.Question.trim(),
              options,
              correctOption: correctOptions,
              exam: examId,
              instituteId: instituteId,
              vendorId: vendorId,
            };
          });

          try {
            const response = await axios.post(
              "/api/v1/question/admin-add-exam-to-csv-questions",
              {
                questions: formattedQuestions,
                exam: examId,
              },
              {
                onUploadProgress: (progressEvent) => {
                  const progress =
                    (progressEvent.loaded / progressEvent.total) * 100;
                  setUploadProgress(progress);
                },
              }
            );

            if (response?.data?.success) {
              message.success(response?.data?.message);
              fileInputRef.current.value = null; // Reset file input
            } else {
              message.error(response?.data?.message);
            }
          } catch (error) {
            message.error(error?.response?.data?.message);
          } finally {
            setIsUploading(false);
          }
        }
      },
    });
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          pb: 1,
        }}
      >
        <Box>{csvFile?.name}</Box>
        <input
          id="csv-file-input"
          type="file"
          accept=".csv"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <Button
          startIcon={<CloudUploadIcon />}
          onClick={() => fileInputRef.current.click()}
          variant="outlined"
          component="span"
          color="primary"
          className="courses_desc"
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "5px",
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            letterSpacing: ".1rem",
          }}
        >
          Open and Upload CSV File
        </Button>
        {isUploading && (
          <LinearProgress variant="determinate" value={uploadProgress} />
        )}

        <Box sx={{ ml: 2 }}>
          <DownloadCSV />
        </Box>
      </Box>
      <Container
        onSubmit={handleSubmitQuestion}
        component="form"
        className="form_style border-style"
        maxWidth
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <TextField
            sx={{
              mb: 3,

              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="name"
            label="Question"
            name="name"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Question"
            value={addQuestionName}
            onChange={(e) => setAddQuestionName(e.target.value)}
            multiline
            margin="normal"
          />
        </Box>
        <br />
        {inputType === "options" && (
          <Grid container spacing={8} rowSpacing={1} columnSpacing={4}>
            {options.map((option, i) => (
              <Grid item xs={12} sm={6} md={6} key={i}>
                <Box sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    id={`option-${i}`}
                    label={`Option ${i + 1}`}
                    name={`option-${i}`}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={`Option ${i + 1}`}
                    value={option}
                    onChange={(e) => handleChangeInputs(e, i)}
                    required
                    multiline
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={correctOption.includes(i)}
                        onChange={() => handleCheckboxChange(i)}
                        name={`correct-option-${i}`}
                      />
                    }
                    label={`Correct Option`}
                  />
                  {options.length > 1 ? (
                    <Button
                      sx={{ mt: 1, mb: 1, fontSize: "14px" }}
                      onClick={() => handleDeleteInputs(i)}
                      color="error"
                    >
                      Remove
                    </Button>
                  ) : null}
                  {i + 1 == options.length ? (
                    <Button
                      variant="outlined"
                      sx={{ mb: 2, mt: 2, ml: 2 }}
                      onClick={handleAddMore}
                      color="success"
                      startIcon={<AddIcon />}
                    >
                      Add More Option
                    </Button>
                  ) : null}
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
        <br />
        <Button
          variant="contained"
          type="submit"
          color="primary"
          className="courses_desc"
          sx={{
            mt: 0,
            borderRadius: "5px",
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            letterSpacing: ".1rem",
          }}
        >
          Add Question
        </Button>
      </Container>
    </div>
  );
};

export default AddQuestions;
