import React, { useState } from "react";
import { Container, Box, TextField, Button } from "@mui/material";
import axios from "../../axiosInstance";
import { useParams } from "react-router-dom";
import { message } from "antd";
import { useSelector } from "react-redux";
import Papa from "papaparse";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadCSV from "./DownloadCSV";

const AddSubjectiveQuestion = ({ vendor_Id, institute_Id }) => {
  const { user } = useSelector((state) => state.user);
  const [instituteId, setInstituteId] = useState(institute_Id);
  const [vendorId, setVendorId] = useState(vendor_Id);
  const [csvFile, setCsvFile] = useState(null);
  const [addQuestionName, setAddQuestionName] = useState("");
  const [referenceAnswer, setReferenceAnswer] = useState("");
  const [questionNumber, setQuestionNumber] = useState("");

  // Extracting id from useParams and localStorage
  const { id: paramId } = useParams();
  const localStorageId = JSON.parse(localStorage.getItem("subjectiveId"));
  const examId = paramId || localStorageId;

  // Handle file selection and upload CSV
  const handleCsvFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setCsvFile(file);
    handleCsvUpload(file);
  };

  // Upload CSV File
  const handleCsvUpload = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (parsed) => {
        try {
          if (parsed.errors.length > 0) {
            console.error("Parsing error:", parsed.errors);
            throw new Error("Parsing error occurred");
          }

          const { data } = parsed;
          if (data.length === 0) {
            message.warning("CSV file is empty");
            return;
          }

          const formattedQuestions = data.map((row) => ({
            name: row.Name,
            referenceAnswer: row["Reference Answer"],
            questionNumber: row["Question Number"],
            subjectiveExam: examId,
            instituteId: instituteId,
            vendorId: vendorId,
          }));

          if (formattedQuestions.length > 0) {
            const response = await axios.post(
              "/api/v1/question/subjective/admin-add-exam-to-csv-subjectiveQuestions",
              {
                subjectiveQuestions: formattedQuestions,
                subjectiveExam: examId,
              }
            );

            if (response.data.success) {
              message.success(response.data.message);
              setAddQuestionName([]);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.warning("No valid data found in CSV");
          }
        } catch (error) {
          console.error("Error during CSV upload:", error);
          message.error("Error during CSV upload");
        }
      },
    });
  };

  // Handle Submit Question API
  const handleSubmitQuestion = async (e) => {
    e.preventDefault();
    if (addQuestionName.length === 0) {
      message.error("Please enter Question Name");
      return;
    }

    const subjectiveQuestions = {
      name: addQuestionName,
      referenceAnswer,
      questionNumber,
      subjectiveExam: examId,
      instituteId: instituteId,
      vendorId: vendorId,
    };

    try {
      const data = await axios.post(
        "/api/v1/question/subjective/admin-add-exam-to-subjectiveQuestions",
        subjectiveQuestions
      );

      if (data.data.success === true) {
        message.success(data.data.message);
        setAddQuestionName("");
        setReferenceAnswer("");
        setQuestionNumber("");
      } else {
        message.error(data?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          pb: 1,
        }}
      >
        <Box>{csvFile?.name}</Box>
        <input
          accept=".csv"
          id="csv-file-input"
          type="file"
          style={{ display: "none" }}
          onChange={handleCsvFileChange}
        />
        <label htmlFor="csv-file-input">
          <Button
            variant="outlined"
            component="span"
            startIcon={<CloudUploadIcon />}
            color="primary"
            className="courses_desc"
            sx={{
              mb: 2,
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "5px",
              textTransform: "none",
              fontFamily: "Poppins, sans-serif",
              letterSpacing: ".1rem",
            }}
          >
            Open and Upload CSV File
          </Button>
        </label>
        <Box sx={{ ml: 2 }}>
          <DownloadCSV />
        </Box>
      </Box>
      <Container
        onSubmit={handleSubmitQuestion}
        component="form"
        className="form_style border-style"
        maxWidth
      >
        <TextField
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="name"
          label="Question"
          name="name"
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          placeholder="Question"
          value={addQuestionName}
          onChange={(e) => setAddQuestionName(e.target.value)}
          multiline
        />

        <TextField
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            "& input[type='number']": {
              MozAppearance: "textfield",
              "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                display: "none",
              },
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="questionNumber"
          label="Question Marks"
          name="questionNumber"
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          type="Number"
          placeholder="Question Marks"
          value={questionNumber}
          onChange={(e) => setQuestionNumber(e.target.value)}
        />

        <TextField
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="name"
          label="Reference Answer"
          name="referenceAnswer"
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          placeholder="Reference Answer"
          value={referenceAnswer}
          onChange={(e) => setReferenceAnswer(e.target.value)}
          multiline
        />

        <Button
          variant="contained"
          type="submit"
          color="primary"
          className="courses_desc"
          sx={{
            mt: 3,
            borderRadius: "5px",
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            letterSpacing: ".1rem",
          }}
        >
          Add Subjective Question
        </Button>
      </Container>
    </>
  );
};

export default AddSubjectiveQuestion;
