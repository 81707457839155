import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  TextField,
  Popover,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { message, Modal } from "antd";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "../axiosInstance";
import PageTitle from "../Components/PageTitle/PageTitle";
import SearchIcon from "@mui/icons-material/Search";
import PublicUserTableAddBatch from "./PublicUserTableAddBatch";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';


const PublicUser = () => {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredVendors, setFilteredVendors] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverData, setPopoverData] = useState([]);
  const navigate = useNavigate();
  
  const handleClick = (event, data) => {
    setPopoverData(data);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // get all Public user
  const displayUsers = async () => {
    try {
      // const { data } = await axios.get(`/api/v1/auth/admin-get-all-user`);
      const { data } = await axios.get(
        `/api/v1/public/user/admin-get-institute-by-publicUser`
      );
      setUsers(data?.publicUsers);
      setFilteredVendors(data?.publicUsers);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    displayUsers();
  }, []);

  // Add Public student in batch 
  const { confirm } = Modal;
  const addUser = async (e, id) => {
    confirm({
      title: "Add Student to Batch?",
      content:
        "Are you sure you want to add the selected student to the batch?",
      okText: "Yes, Add",
      cancelText: "No, Cancel",
      onOk: async () => {
        navigate('/admin/add/public/student', {
          state: { users, filteredVendors },
        });
       }, onCancel() {
        
      },
  })
  }

  //delete public user
  const deleteUser = async (e, id) => {
    confirm({
      title: "Are you sure you want to delete this Student?",
      content:
        "This action will permanently delete the student from the system.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/public/user/admin-delete-user-publicUser",
            {
              data: { id },
            }
          );
          if (data?.success === true) {
            message.success(data?.message);
            displayUsers();
          } else {
            message.error(data?.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to delete student"
          );
        }
      },
      onCancel() {
        console.log("Delete operation canceled");
      },
    });
  };



  // All Public User Download PFD
const exportPDF = () => {
  Modal.confirm({
    title: "Confirm Download",
    content: "Do you want to download the All Users List as a PDF?",
    okText: "Yes, Download",
    cancelText: "No, Cancel",
    onOk: () => {
      const doc = new jsPDF();
      const columns = [
        "#",
        "Institute Id",
        "Name",
        "Email",
        "Phone",
        "Course Name",
        "Course Fees",
        "Course Start Date",
        "Create Date",
      ];

      // Check for empty user data
      if (!users || users.length === 0) {
        message.error("No user data available to export.");
        return;
      }

      // Array to hold all rows of data
      const allRows = [];

      // Map users data to rows
      users.forEach((item, index) => {
        const name = item?.name || "N/A";
        const email = item?.email || "N/A";
        const phone = item?.phone || "N/A";
        const createdAt = item.createdAt
          ? moment(item.createdAt).format("MM/DD/YYYY")
          : "N/A";

        // If there are multiple courses in requestUser, map through each
        if (item.requestUser && item.requestUser.length > 0) {
          item.requestUser.forEach((requestItem) => {
            const instituteId = requestItem?.instituteId || "N/A";
            const courseId = requestItem?.courseId;
            const courseName = courseId?.courseName || "N/A";
            const courseFees = courseId?.courseFees || "N/A";
            const startDate = courseId?.startDate
              ? moment(courseId?.startDate).format("MM/DD/YYYY")
              : "N/A";

            // Push the row for each course
            allRows.push([
              index + 1,
              instituteId,
              name,
              email,
              phone,
              courseName,
              courseFees,
              startDate,
              createdAt,
            ]);
          });
        } else {
          allRows.push([
            index + 1,
            "N/A",
            name,
            email,
            phone,
            "N/A",
            "N/A",
            "N/A",
            createdAt,
          ]);
        }
      });

      // Add title
      doc.text("All Users List", 14, 10);

      // Generate the PDF with autotable
      doc.autoTable({
        head: [columns],
        body: allRows,
        startY: 20,
      });

      // Save the PDF
      doc.save("All_Users_List.pdf");
    },
    onCancel() {
      console.log("Download canceled");
    },
  });
};


  // Handle search input change
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter vendors based on search query
    const filtered = users.filter(
      (user) =>
        user.name.toLowerCase().includes(query) ||
        user.email.toLowerCase().includes(query)
    );
    setFilteredVendors(filtered);
  };

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 10,
      sortable: false,
    },
    {
      field: "Actions",
      width: 150,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteUser(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>

          <IconButton
            aria-label="add"
             color="primary"
            onClick={(e) => addUser(e, value?.row?._id)}
          >
          
            <AddCircleOutlineOutlinedIcon  />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "courseDetails",
      headerName: "Course Details",
      width: 250,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          className="courses_desc"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "50px",
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            letterSpacing: ".1rem",
          }}
          onClick={(e) =>
            handleClick(
              e,
              params.row.requestUser?.map((item) => ({
                instituteId: item.instituteId || "N/A",
                courseName: item.courseId?.courseName || "N/A",
                courseFees: item.courseId?.courseFees || "N/A",
                startDate: item.courseId?.startDate
                  ? moment(item.courseId?.startDate).format("MM/DD/YYYY")
                  : "N/A",
              }))
            )
          }
        >
          Course Details
        </Button>
      ),
    },
    {
      field: "name",
      headerName: "Full Name",
      width: 230,
      editable: true,
    },
    {
      field: "email",
      headerName: "E-mail",
      width: 260,
      editable: true,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 200,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Request Date",
      width: 180,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY"),
    },
  ];

  return (
    <div>
      <PageTitle title="Admin Dashboard | Test Series" />
      <Box >
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          All Public Student List
        </Typography>

        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>Public Student</Typography>
        </Breadcrumbs>

        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by Student Name And Email"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon sx={{ color: "#1976d2" }} />
              </IconButton>
            ),
          }}
          sx={{
            mb: 3,
            bgcolor: "white",
            borderRadius: "20px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
              "&:hover fieldset": {
                border: "none",
              },
              "&.Mui-focused fieldset": {
                border: "none",
              },
            },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={exportPDF}
          startIcon={<DownloadIcon />}
          className="courses_desc"
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "5px",
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            letterSpacing: ".1rem",
          }}
        >
          Export to PDF
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
            
          }}
         
        >
          <Box p={2} sx={{ maxHeight: 300, overflowY: "scroll" }}>
            {" "}
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              sx={{
                fontFamily: "Poppins, sans-serif",
                color: "#2C3E50",
                letterSpacing: "1.5px",
                lineHeight: 1.5,
              }}
            >
              Course Details:
            </Typography>
            <Table sx={{ minWidth: 300 }} aria-label="course details table">
              <TableHead
                sx={{
                  backgroundColor: "rgb(25, 118, 210)",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "white",
                      fontWeight: "800",
                      fontSize: "15px",
                    }}
                  >
                    Institute ID
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "white",
                      fontWeight: "800",
                      fontSize: "15px",
                    }}
                  >
                    Course Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "white",
                      fontWeight: "800",
                      fontSize: "15px",
                    }}
                  >
                    Course Fees
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "white",
                      fontWeight: "800",
                      fontSize: "15px",
                    }}
                  >
                    Start Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {popoverData.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        color: "#555",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        color: "#555",
                      }}
                    >
                      {data.instituteId}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        color: "#555",
                      }}
                    >
                      {data.courseName}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        color: "#555",
                      }}
                    >
                      {data.courseFees}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        color: "#555",
                      }}
                    >
                      {data.startDate}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Popover>
        <Paper
          sx={{
            bgcolor: "white",
            borderRadius: 3,
            overflow: "hidden",
          }}
        >
          <Box sx={{ height: 550, width: "100%", }}>
            <DataGrid
              getRowId={(row) => row?._id}
              sx={{
                "& .MuiTablePagination-displayedRows": {
                  color: "black",
                },
                color: "black",
                [`& .${gridClasses?.row}`]: {
                  bgcolor: "white",
                },
                // Style for header cells
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "rgb(25, 118, 210)",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "white",
                  fontWeight: "bold",
                  // fontSize: "13px",
                },
                // Style for checkbox in header
                "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                  color: "#FFF",
                },
                "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white",
                },
              }}
              rows={filteredVendors.map((row, index) => ({
                ...row,
                rowNumber: index + 1,
              }))}
              columns={columns}
              pageSize={3}
              rowsPerPageOptions={[3]}
            />
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default PublicUser;
