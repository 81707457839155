import React, { useState, useEffect } from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import { Box, Typography, Paper, TextField, Button } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { message, Modal } from "antd";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import SearchIcon from "@mui/icons-material/Search";
import axios from "../../axiosInstance";
import { useSelector } from "react-redux";

const SubjectiveReports = () => {
  const { user } = useSelector((state) => state.user);
  const [reportsData, setReportsData] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [filters, setFilters] = useState({
    examId: "",
    userId: "",
  });

  // Get all reports by user
  const getReports = async (filters) => {
    try {
      const response = await axios.post(
        `/api/v1/reports/subjective/admin-get-all-Reports-subjectiveReport`,
        filters
      );
      setReportsData(response?.data?.data);
      setFilteredReports(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getReports(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Search functionality
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = reportsData.filter((report) => {
      return (
        report.examName.toLowerCase().includes(query) ||
        report.userEmail.toLowerCase().includes(query)
      );
    });

    setFilteredReports(filtered);
  };

  // Function to export data to PDF with index column
const exportPDF = () => {
  // Show confirmation modal before downloading the PDF
  Modal.confirm({
    title: "Confirm Download",
    content: "Do you want to download the Student Report as a PDF?",
    okText: "Yes, Download",
    cancelText: "No, Cancel",
    onOk: () => {
      const doc = new jsPDF();
      const columns = [
        "#",
        "Exam Name",
        "User Email",
        "Total Marks",
        "Passing Marks",
        "Obtained Marks",
        "Verdict",
        "Report Date",
      ];

      const rows = filteredReports.map((report, index) => [
        index + 1,
        report.examName,
        report.userEmail,
        report.totalMarks,
        report.passingMarks,
        report.totalMarksObtained,
        report.verdict,
        moment(report.createdAt).format("MM/DD/YYYY"),
      ]);

      // Add title
      doc.text("Student All Report", 16, 16);

      // Generate the PDF with autotable
      doc.autoTable({
        head: [columns],
        body: rows,
        startY: 20,
      });

      // Save the PDF
      doc.save("Student_Report.pdf");
    },
    onCancel() {
      console.log("Download canceled");
    },
  });
};
  // Delete report by Id
const deleteReport = async (e, id) => {
  Modal.confirm({
    title: "Are you sure you want to delete this Subjective Report?",
    content:
      "This action will permanently delete the subjective report. Are you sure you want to continue?",
    okText: "Yes, Delete",
    cancelText: "No, Cancel",
    onOk: async () => {
      try {
        const { data } = await axios.delete(
          "/api/v1/reports/subjective/admin-delete-subjectiveReport",
          {
            data: { id },
          }
        );
        if (data.success === true) {
          message.success(data.message);
          getReports();
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    },
    onCancel() {
      console.log("Deletion canceled");
    },
  });
};


  const columns = [
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteReport(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "examName",
      headerName: "Exam Name",
      width: 160,
      editable: true,
    },
    // {
    //   field: "instituteId",
    //   headerName: "Institute Id",
    //   width: 120,
    //   editable: true,
    // },
    {
      field: "category",
      headerName: "Exam Category",
      width: 120,
      editable: true,
    },
    {
      field: "examSet",
      headerName: "Exam Type",
      width: 120,
      editable: true,
    },
    {
      field: "userEmail",
      headerName: "Student Email",
      width: 120,
      editable: true,
    },
    {
      field: "totalMarks",
      headerName: "Total Marks",
      width: 120,
      editable: true,
    },
    {
      field: "passingMarks",
      headerName: "Passing Marks",
      width: 120,
      editable: true,
    },
    {
      field: "totalMarksObtained",
      headerName: "Obtained Marks",
      width: 120,
      renderCell: (row) => row?.row?.totalMarksObtained,
    },
    {
      field: "verdict",
      headerName: "Verdict",
      width: 120,
    },
    {
      field: "date",
      headerName: "Report Date",
      width: 120,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY"),
    },
  ];
   // Conditionally add the instituteId column if user.role === 1
   if (user && user.role === 1) {
    columns.splice(1, 0, {
      field: "instituteId",
      headerName: "Institute Id",
      width: 180,
      editable: true,
    });
  }

  return (
    <div>
      <PageTitle title="Admin Subjective Exam Reports | Test Series" />
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          mb: 3,
          fontFamily: "Poppins, sans-serif",
          color: "#2C3E50",
          letterSpacing: "2.5px",
          lineHeight: 1.8,
        }}
      >
        Subjective Reports
      </Typography>

      <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by Exam Name or User Email"
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon sx={{ color: "#1976d2" }} />
              </IconButton>
            ),
          }}
          sx={{
            mb: 3,
            bgcolor: "white",
            borderRadius: "20px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
              "&:hover fieldset": {
                border: "none",
              },
              "&.Mui-focused fieldset": {
                border: "none",
              },
            },
          }}
        />
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={exportPDF}
        startIcon={<DownloadIcon />}
        className="courses_desc"
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "5px",
          textTransform: "none",
          fontFamily: "Poppins, sans-serif",
          letterSpacing: ".1rem",
        }}
      >
        Export to PDF
      </Button>

      <Paper sx={{ bgcolor: "white" }}>
        <Box sx={{ height: 500, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row?._id}
            sx={{
              "& .MuiTablePagination-displayedRows": {
                color: "black",
              },
              color: "black",
              [`& .${gridClasses?.row}`]: {
                bgcolor: "white",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgb(25, 118, 210)",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                color: "#FFF",
              },
              "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                color: "white",
              },
            }}
            rows={filteredReports}
            columns={columns}
            pageSize={3}
            rowsPerPageOptions={[3]}
          />
        </Box>
      </Paper>
    </div>
  );
};

export default SubjectiveReports;
