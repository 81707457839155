// import React, { useState, useEffect } from "react";
// import Navbar from "../Components/Navbar";
// import PageTitle from "../Components/PageTitle/PageTitle";
// import { Box, Typography, Paper, Grid, Card, CardContent } from "@mui/material";
// import { DataGrid, gridClasses } from "@mui/x-data-grid";
// import moment from "moment";
// import axios from "../axiosInstance";
// import { message } from "antd";
// import Footer from "../Components/Footer";
// import CircularProgress from "@mui/material/CircularProgress";
// import useInstituteUser from "../Components/GetInstituteUser";

// const Reports = () => {
//   const studentCourse = useInstituteUser();
//   const [reportsData, setReportsData] = useState([]);
//   const [subjectiveReportsData, setSubjectiveReportsData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [coursesMap, setCoursesMap] = useState({});

//   // Fetch all institute courses
//   const studentCourseData = () => {
//     if (studentCourse) {
//       const map = {};
//       studentCourse.forEach((course) => {
//         map[course.studentCourseId] = course.studentCourseName;
//       });
//       setCoursesMap(map);
//     }
//   };
//   // Get All Reports
//   const getData = async () => {
//     try {
//       const response = await axios.post(
//         `/api/v1/reports/get-all-reports-by-user`,
//         { studentCourse }
//       );
//       setReportsData(response?.data?.data);
//     } catch (error) {
//       message.error(error?.response?.data?.message);
//     } finally {
//       setLoading(false);
//     }
//   };
//   // Get All Subjective Reports
//   const getAllSubjectiveReports = async () => {
//     try {
//       const response = await axios.post(
//         `/api/v1/reports/subjective/get-all-subjectiveReport-by-user`,
//         { studentCourse }
//       );
//       setSubjectiveReportsData(response?.data?.data);
//     } catch (error) {
//       message.error(error?.response?.data?.message);
//     }
//   };
//   useEffect(() => {
//     if (studentCourse) {
//       getData(studentCourse);
//       getAllSubjectiveReports(studentCourse);
//       studentCourseData(studentCourse);
//     }
//   }, [studentCourse]);

//   const mergedData = [...reportsData, ...subjectiveReportsData];

//   const columns = [
//     {
//       field: "rowNumber",
//       headerName: "#",
//       width: 20,
//       sortable: false,
//     },
//     {
//       field: "courseName",
//       headerName: "Course Name",
//       width: 160,
//       renderCell: (params) =>
//         coursesMap[params.row.instituteCourseId] || "Unknown Course",
//     },
//     {
//       field: "examName",
//       headerName: "Exam Name",
//       width: 200,
//       editable: true,
//       valueGetter: (params) => params.row.exam?.name || params.row.examName,
//     },
//     {
//       field: "category",
//       headerName: "Exam Category",
//       width: 180,
//       editable: true,
//       valueGetter: (params) => params.row.exam?.category || params.row.category,
//     },
//     {
//       field: "examSet",
//       headerName: "Exam Type",
//       width: 180,
//       editable: true,
//     },
//     {
//       field: "totalMarks",
//       headerName: "Total Marks",
//       width: 130,
//       editable: true,
//     },
//     {
//       field: "passingMarks",
//       headerName: "Passing Marks",
//       width: 130,
//       editable: true,
//     },
//     {
//       field: "obtainedMarks",
//       headerName: "Obtained Marks",
//       width: 130,
//       renderCell: (row) => {
//         const obtainedMarks =
//           row?.row?.result?.obtainedMarks || row?.row?.totalMarksObtained;
//         return obtainedMarks ? obtainedMarks.toFixed(2) : "0.00";
//       },
//     },
//     {
//       field: "resultVerdict",
//       headerName: "Result",
//       width: 130,
//       editable: true,
//       renderCell: (row) => row?.row?.result?.verdict || row?.row?.verdict,
//     },
//     {
//       field: "date",
//       headerName: "Date",
//       width: 120,
//       editable: true,
//       renderCell: (params) =>
//         moment(params?.row?.createdAt).format("MM/DD/YYYY"),
//     },
//   ];

//   return (
//     <>
//       <Navbar />
//       <PageTitle title="Exam Reports | Test Series" />
//       <Box
//         sx={{
//           pl: { xs: 2, sm: 2, md: 12, lg: 14 },
//           pr: { xs: 2, sm: 2, md: 12, lg: 14 },
//           pt: 3,
//           minHeight: "100vh",
//           mt: { xs: 12, sm: 12, md: 12, lg: 14 },
//           mb: 12,
//         }}
//       >
//         <Typography
//           gutterBottom
//           variant="h3"
//           component="div"
//           color="text.secondary"
//           sx={{
//             pb: 3,
//             textAlign: "center",
//             letterSpacing: "2.5px",
//             lineHeight: 1.8,
//             fontSize: "30px",
//             fontFamily: "Poppins, sans-serif",
//             fontWeight: "bold",
//             color: "#555",
//           }}
//         >
//           Exam Reports
//         </Typography>

//         {loading ? (
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               minHeight: "50vh",
//             }}
//           >
//             <CircularProgress />
//           </Box>
//         ) : (
//           <Paper sx={{ bgcolor: "white" }}>
//             <Box sx={{ height: 500, width: "100%" }}>
//               <DataGrid
//                 getRowId={(row) => row?._id}
//                 sx={{
//                   "& .MuiTablePagination-displayedRows": {
//                     color: "black",
//                   },
//                   color: "black",
//                   [`& .${gridClasses?.row}`]: {
//                     bgcolor: "white",
//                   },
//                   // Style for header cells
//                   "& .MuiDataGrid-columnHeaders": {
//                     backgroundColor: "rgb(25, 118, 210)",
//                   },
//                   "& .MuiDataGrid-columnHeaderTitle": {
//                     color: "white",
//                     fontWeight: "bold",
//                     // fontSize: "13px",
//                   },
//                   // Style for checkbox in header
//                   "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
//                     color: "#FFF",
//                   },
//                   "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
//                     color: "white",
//                   },
//                   "& .MuiDataGrid-sortIcon": {
//                     color: "white",
//                   },
//                 }}
//                 // rows={mergedData}
//                 rows={mergedData.map((row, index) => ({
//                   ...row,
//                   rowNumber: index + 1,
//                 }))}
//                 columns={columns}
//                 pageSize={5}
//                 rowsPerPageOptions={[5, 10, 20]}
//                 // checkboxSelection
//               />
//             </Box>
//           </Paper>
//         )}

        // <Box sx={{ mt: 5 }}>
        //   <Typography
        //     variant="h6"
        //     gutterBottom
        //     // variant="h3"
        //     component="div"
        //     color="text.secondary"
        //     sx={{
        //       mb: 3,
        //       textAlign: "center",
        //       letterSpacing: "2.5px",
        //       lineHeight: 1.8,
        //       fontSize: "30px",
        //       fontFamily: "Poppins, sans-serif",
        //       fontWeight: "bold",
        //       color: "#555",
        //     }}
        //   >
        //     Summary
        //   </Typography>
        //   <Grid container spacing={3}>
        //     <Grid item xs={12} md={4}>
        //       <Card
        //         s
        //         sx={{
        //           transition: "0.3s !important",
        //           "&:hover": { transform: "scale(1.05)" },
        //           borderRadius: "15px",
        //           boxShadow:
        //             "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
        //           padding: "16px",
        //           height: "100%",
        //           display: "flex",
        //           flexDirection: "column",
        //           justifyContent: "space-between",
        //           bgcolor: "#f5f5f5",
        //         }}
        //       >
        //         <CardContent>
        //           <Typography
        //             gutterBottom
        //             variant="h6"
        //             color="primary"
        //             sx={{
        //               fontFamily: "Poppins, sans-serif",
        //               lineHeight: 1.8,
        //               letterSpacing: "1.8px",
        //             }}
        //           >
        //             Total Exams Taken
        //           </Typography>
        //           <Typography
        //             variant="h4"
        //             gutterBottom
        //             sx={{
        //               fontFamily: "Poppins, sans-serif",
        //               letterSpacing: "1.8px",
        //             }}
        //           >
        //             {mergedData.length}
        //           </Typography>
        //         </CardContent>
        //       </Card>
        //     </Grid>
        //     <Grid item xs={12} md={4}>
        //       <Card
        //         sx={{
        //           transition: "0.3s !important",
        //           "&:hover": { transform: "scale(1.05)" },
        //           borderRadius: "15px",
        //           boxShadow:
        //             "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
        //           padding: "16px",
        //           height: "100%",
        //           display: "flex",
        //           flexDirection: "column",
        //           justifyContent: "space-between",
        //           bgcolor: "#f5f5f5",
        //         }}
        //       >
        //         <CardContent>
        //           <Typography
        //             gutterBottom
        //             variant="h6"
        //             color="primary"
        //             sx={{
        //               fontFamily: "Poppins, sans-serif",
        //               lineHeight: 1.8,
        //               letterSpacing: "1.8px",
        //             }}
        //           >
        //             Average Score
        //           </Typography>
        //           <Typography
        //             variant="h4"
        //             gutterBottom
        //             sx={{
        //               fontFamily: "Poppins, sans-serif",
        //               letterSpacing: "1.8px",
        //             }}
        //           >
        //             {(
        //               mergedData.reduce(
        //                 (acc, report) =>
        //                   acc +
        //                   (report?.result?.obtainedMarks ||
        //                     report?.totalMarksObtained ||
        //                     0),
        //                 0
        //               ) / mergedData.length
        //             ).toFixed(2)}
        //           </Typography>
        //         </CardContent>
        //       </Card>
        //     </Grid>
        //     <Grid item xs={12} md={4}>
        //       <Card
        //         sx={{
        //           transition: "0.3s !important",
        //           "&:hover": { transform: "scale(1.05)" },
        //           borderRadius: "15px",
        //           boxShadow:
        //             "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
        //           padding: "16px",
        //           height: "100%",
        //           display: "flex",
        //           flexDirection: "column",
        //           justifyContent: "space-between",
        //           bgcolor: "#f5f5f5",
        //         }}
        //       >
        //         <CardContent>
        //           <Typography
        //             gutterBottom
        //             variant="h6"
        //             color="primary"
        //             sx={{
        //               fontFamily: "Poppins, sans-serif",
        //               lineHeight: 1.8,
        //               letterSpacing: "1.8px",
        //             }}
        //           >
        //             Success Rate
        //           </Typography>
        //           <Typography
        //             variant="h4"
        //             gutterBottom
        //             sx={{
        //               fontFamily: "Poppins, sans-serif",
        //               letterSpacing: "1.8px",
        //             }}
        //           >
        //             {(
        //               (mergedData.filter(
        //                 (report) =>
        //                   (report?.result?.verdict || report?.verdict) ===
        //                   "Pass"
        //               ).length /
        //                 mergedData.length) *
        //               100
        //             ).toFixed(2)}
        //             %
        //           </Typography>
        //         </CardContent>
        //       </Card>
        //     </Grid>
        //   </Grid>
        // </Box>
//       </Box>
//       <Footer />
//     </>
//   );
// };

// export default Reports;


import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import { Box, Typography, Paper, Grid, Card, CardContent } from "@mui/material";
import moment from "moment";
import axios from "../axiosInstance";
import { message, Table } from "antd";
import Footer from "../Components/Footer";
import CircularProgress from "@mui/material/CircularProgress";
import useInstituteUser from "../Components/GetInstituteUser";

const Reports = () => {
  const studentCourse = useInstituteUser();
  const [reportsData, setReportsData] = useState([]);
  const [subjectiveReportsData, setSubjectiveReportsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [coursesMap, setCoursesMap] = useState({});

  // Fetch all institute courses
  const studentCourseData = () => {
    if (studentCourse) {
      const map = {};
      studentCourse.forEach((course) => {
        map[course.studentCourseId] = course.studentCourseName;
      });
      setCoursesMap(map);
    }
  };
  
  // Get All Reports
  const getData = async () => {
    try {
      const response = await axios.post(
        `/api/v1/reports/get-all-reports-by-user`,
        { studentCourse }
      );
      setReportsData(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  
  // Get All Subjective Reports
  const getAllSubjectiveReports = async () => {
    try {
      const response = await axios.post(
        `/api/v1/reports/subjective/get-all-subjectiveReport-by-user`,
        { studentCourse }
      );
      setSubjectiveReportsData(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  
  useEffect(() => {
    if (studentCourse) {
      getData(studentCourse);
      getAllSubjectiveReports(studentCourse);
      studentCourseData(studentCourse);
    }
  }, [studentCourse]);

  const mergedData = [...reportsData, ...subjectiveReportsData].map((row, index) => ({
    ...row,
    key: row._id,
    rowNumber: index + 1,
  }));

  const columns = [
    {
      title: "#",
      dataIndex: "rowNumber",
      key: "rowNumber",
      width: 50,
    },
    {
      title: "Course Name",
      dataIndex: "courseName",
      key: "courseName",
      render: (_, record) => coursesMap[record.instituteCourseId] || "Unknown Course",
      width: 160,
    },
    {
      title: "Exam Name",
      dataIndex: "examName",
      key: "examName",
      render: (_, record) => record.exam?.name || record.examName,
      width: 200,
    },
    {
      title: "Exam Category",
      dataIndex: "category",
      key: "category",
      render: (_, record) => record.exam?.category || record.category,
      width: 180,
    },
    {
      title: "Exam Type",
      dataIndex: "examSet",
      key: "examSet",
      width: 180,
    },
    {
      title: "Total Marks",
      dataIndex: "totalMarks",
      key: "totalMarks",
      width: 130,
    },
    {
      title: "Passing Marks",
      dataIndex: "passingMarks",
      key: "passingMarks",
      width: 130,
    },
    {
      title: "Obtained Marks",
      dataIndex: "obtainedMarks",
      key: "obtainedMarks",
      render: (_, record) => {
        const obtainedMarks = record?.result?.obtainedMarks || record?.totalMarksObtained;
        return obtainedMarks ? obtainedMarks.toFixed(2) : "0.00";
      },
      width: 130,
    },
    {
      title: "Result",
      dataIndex: "resultVerdict",
      key: "resultVerdict",
      render: (_, record) => record?.result?.verdict || record?.verdict,
      width: 130,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_, record) => moment(record?.createdAt).format("MM/DD/YYYY"),
      width: 120,
    },
  ];

  return (
    <>
      <Navbar />
      <PageTitle title="Exam Reports | Test Series" />
      <Box
        sx={{
          pl: { xs: 2, sm: 2, md: 12, lg: 14 },
          pr: { xs: 2, sm: 2, md: 12, lg: 14 },
          pt: 3,
          minHeight: "100vh",
          mt: { xs: 12, sm: 12, md: 12, lg: 14 },
          mb: 12,
        }}
      >
        <Typography
          gutterBottom
          variant="h3"
          component="div"
          color="text.secondary"
          sx={{
            pb: 3,
            textAlign: "center",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
            fontSize: "30px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "bold",
            color: "#555",
          }}
        >
          Exam Reports
        </Typography>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Paper sx={{ bgcolor: "white",  }}>
            <Table
              columns={columns}
              dataSource={mergedData}
              pagination={{ pageSize: 5 }}
              scroll={{ x: 800 }}
              // bordered
            style={{ backgroundColor: "white" }}
            rowClassName={() => "custom-row"}
            className="custom-table"
            />
          </Paper>
        )}
        <Box sx={{ mt: 5 }}>
          <Typography
            variant="h6"
            gutterBottom
            // variant="h3"
            component="div"
            color="text.secondary"
            sx={{
              mb: 3,
              textAlign: "center",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
              fontSize: "30px",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
              color: "#555",
            }}
          >
            Summary
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card
                s
                sx={{
                  transition: "0.3s !important",
                  "&:hover": { transform: "scale(1.05)" },
                  borderRadius: "15px",
                  boxShadow:
                    "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                  padding: "16px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  bgcolor: "#f5f5f5",
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    color="primary"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      lineHeight: 1.8,
                      letterSpacing: "1.8px",
                    }}
                  >
                    Total Exams Taken
                  </Typography>
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      letterSpacing: "1.8px",
                    }}
                  >
                    {mergedData.length}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  transition: "0.3s !important",
                  "&:hover": { transform: "scale(1.05)" },
                  borderRadius: "15px",
                  boxShadow:
                    "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                  padding: "16px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  bgcolor: "#f5f5f5",
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    color="primary"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      lineHeight: 1.8,
                      letterSpacing: "1.8px",
                    }}
                  >
                    Average Score
                  </Typography>
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      letterSpacing: "1.8px",
                    }}
                  >
                    {(
                      mergedData.reduce(
                        (acc, report) =>
                          acc +
                          (report?.result?.obtainedMarks ||
                            report?.totalMarksObtained ||
                            0),
                        0
                      ) / mergedData.length
                    ).toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  transition: "0.3s !important",
                  "&:hover": { transform: "scale(1.05)" },
                  borderRadius: "15px",
                  boxShadow:
                    "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                  padding: "16px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  bgcolor: "#f5f5f5",
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    color="primary"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      lineHeight: 1.8,
                      letterSpacing: "1.8px",
                    }}
                  >
                    Success Rate
                  </Typography>
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      letterSpacing: "1.8px",
                    }}
                  >
                    {(
                      (mergedData.filter(
                        (report) =>
                          (report?.result?.verdict || report?.verdict) ===
                          "Pass"
                      ).length /
                        mergedData.length) *
                      100
                    ).toFixed(2)}
                    %
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
       
      </Box>
      <Footer />
      <style jsx>{`
        .custom-table .ant-table-thead > tr > th {
          background-color: rgb(25, 118, 210);
          color: white;
          font-weight: bold;
        }
        .custom-table .ant-table-tbody > tr > td {
          color: black;
        }
      `}</style>
    </>
  );
};

export default Reports;
