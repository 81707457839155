// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Typography,
//   Button,
//   Card,
//   CardContent,
//   Container,
//   TextField,
// } from "@mui/material";
// import axios from "../../axiosInstance";
// import { message } from "antd";
// import { useLocation } from "react-router-dom";

// const InstituteFacilities = () => {
//   const [facilities, setFacilities] = useState([]);

//   const location = useLocation();
//   const { _id, instituteId } = location.state || {};

//   // Fetch Institute facilities Details
//   const getInstituteFacilitiesByInstituteId = async () => {
//     try {
//       const response = await axios.post(
//         `/api/v1/vendor/admin-get-institute-facilities`,
//         {
//           instituteId,
//         }
//       );
//       setFacilities(response?.data?.facilities);
//     } catch (error) {
//       message.error(error?.response?.data?.message);
//     }
//   };

//   useEffect(() => {
//     if (instituteId) getInstituteFacilitiesByInstituteId();
//   }, [instituteId]);

//   console.log(facilities, "facilities");

//   return (
//     <div>
//       <Typography
//         style={{
//           fontWeight: "600px",
//           fontSize: "22px",
//           textAlign: "center",
//         }}
//         sx={{ color: "black", pb: 3, mt: 5 }}
//       >
//         <b> Institute Facilities</b>
//       </Typography>
//     </div>
//   );
// };

// export default InstituteFacilities;


import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  CardActions,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import axios from "../../axiosInstance";
import { message } from "antd";
import { useLocation } from "react-router-dom";

const InstituteFacilities = () => {
  const [facilities, setFacilities] = useState([]);
  const location = useLocation();
  const { instituteId } = location.state || {};
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [enquiryMessage, setEnquiryMessage] = useState("");

  // Fetch Institute facilities Details
  const getInstituteFacilitiesByInstituteId = async () => {
    try {
      const response = await axios.post(
        `/api/v1/demo/get-institute-facilities`,
        {
          instituteId,
        }
      );
      setFacilities(response?.data?.facilities);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  // Handle form submission for enquiry
  const handleEnquirySubmit = async () => {
    try {
      const response = await axios.post(`/api/v1/demo/add-institute-enquiry`, {
        instituteId,
        name,
        email,
        phone,
       enquiryMessage,
      });
      message.success(response?.data?.message || "Thank you for your enquiry! We have received your details and will get back to you shortly.");
      // Clear form fields after successful submission
      setName("");
      setEmail("");
      setPhone("");
      setEnquiryMessage("");
    } catch (error) {
      message.error(error?.response?.data?.message || "Failed to submit enquiry.");
    }
  };

  useEffect(() => {
    if (instituteId) getInstituteFacilitiesByInstituteId();
  }, [instituteId]);

  return (
    <>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "24px",
          textAlign: "center",
          color: "#333",
          mb: 4,
          mt:5,
        }}
      >
        Institute Facilities and Enquiry
      </Typography>

      <Grid container spacing={4}>
        {/* Facilities Section */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              height: "100%",
              maxHeight: "500px",
              display: "flex",
              flexDirection: "column",
              borderRadius: "12px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              overflow: "hidden",
            }}
          >
            <CardContent sx={{ flexGrow: 1, overflowY: "auto" }}>
              <Typography variant="h6" sx={{ fontWeight: 500, mb: 2, color: "#1976d2" }}>
              Institute Facilities
              </Typography>
              {facilities.length > 0 ? (
                <List>
                  {facilities.map((facility) =>
                    facility.facilitiesName.map((name, index) => (
                      <React.Fragment key={`${facility._id}-${index}`}>
                        <ListItem>
                          <ListItemText primary={name} />
                        </ListItem>
                        {index < facility.facilitiesName.length - 1 && <Divider />}
                      </React.Fragment>
                    ))
                  )}
                </List>
              ) : (
                <Typography variant="body2" sx={{ color: "#777" }}>
                  No facilities available for this institute.
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Institute Enquiry Form Section */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              height: "100%",
              maxHeight: "500px", // This should match the height of the facilities card
              display: "flex",
              flexDirection: "column",
              borderRadius: "12px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h6" sx={{ mb: 2, color: "#1976d2" }}>
                Institute Enquiry 
              </Typography>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Phone"
                variant="outlined"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Message"
                multiline
                rows={4}
                variant="outlined"
                value={enquiryMessage}
                onChange={(e) => setEnquiryMessage(e.target.value)}
                sx={{ mb: 2 }}
              />
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleEnquirySubmit}
                  sx={{ textTransform: "none", }}
                >
                  Submit Enquiry
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default InstituteFacilities;
