import React, { useState, useEffect } from "react";
import { message, Modal } from "antd";
import { Container, Box, TextField, Button } from "@mui/material";
import axios from "../../axiosInstance";
import { useSelector } from "react-redux";

const EditQuestionModal = ({
  editQuestionModal,
  setEditQuestionModal,
  refreshData,
  examId,
  selectedQuestion,
  setSelectedQuestion,
}) => {
  const [name, setName] = useState("");
  const [correctOption, setCorrectOption] = useState([]);
  const [options, setOptions] = useState([]);
  const [instituteId, setInstituteId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const id = selectedQuestion?._id;
  const { user } = useSelector((state) => state.user);

  // Get Exam By Question ID
  const getExamsByQuestionID = async () => {
    try {
      const response = await axios.post(
        `/api/v1/question/admin-get-exam-to-questions`,
        { id }
      );
      setName(response?.data?.data?.name);
      setCorrectOption(response?.data?.data?.correctOption);
      setOptions(response?.data?.data?.options);
      setInstituteId(response?.data?.data?.instituteId);
      setVendorId(response?.data?.data?.vendorId);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getExamsByQuestionID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On Change Correct Option Array
  const handleChangeCorrectOption = (onChangeValue, i) => {
    const inputData = [...correctOption];
    inputData[i] = onChangeValue.target.value;
    setCorrectOption(inputData);
  };

  // On Change Option Array
  const handleChangeOptions = (onChangeValue, i) => {
    const inputData = [...options];
    inputData[i] = onChangeValue.target.value;
    setOptions(inputData);
  };

  // Question Edit Api Call
  const { confirm } = Modal;
  const handleSubmit = async (e) => {
    e.preventDefault();
    confirm({
      title: "Are you sure you want to Edit this Question?",
      content:
        "Please verify that all the details are correct before updating.",
      okText: "Yes, Edit",
      cancelText: "No, Cancel",
      onOk: async () => {
        const required = {
          name,
          correctOption,
          options,
          questionId: id,
          exam: examId,
          id: id,
          instituteId: instituteId,
          vendorId: vendorId,
        };

        try {
          const response = await axios.put(
            "/api/v1/question/admin-update-exam-to-questions",
            required
          );
          if (response.data.success === true) {
            message.success(response?.data?.message);
            refreshData();
            setEditQuestionModal(false);
          } else {
            message.error(response?.data?.message);
          }
          setSelectedQuestion(null);
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to edit the question."
          );
        }
      },
      onCancel() {
        console.log("Edit action canceled.");
      },
    });
  };

  return (
    <div>
      <Modal
        title={selectedQuestion ? "Edit Question" : "Add Question"}
        visible={editQuestionModal}
        footer={false}
        onCancel={() => {
          setEditQuestionModal(false);
          setSelectedQuestion(null);
        }}
      >
        <Container
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
          maxWidth
        >
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="question"
            label="Question Name"
            name="question"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Question Name"
            value={name}
            multiline
            onChange={(e) => setName(e.target.value)}
          />

          {correctOption &&
            correctOption?.map((data, i) => (
              <>
                <Box sx={{ mb: 2 }} style={{ fontWeight: "600" }}>
                  {/* {i + 0}. Correct Options */}
                </Box>

                <TextField
                  sx={{
                    mb: 3,
                    "& .MuiInputBase-root": {
                      color: "text.secondary",
                    },
                    "& input[type='number']": {
                      MozAppearance: "textfield",
                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                    },
                    fieldset: { borderColor: "rgb(231, 235, 240)" },
                  }}
                  fullWidth
                  id="correctOption"
                  label="Correct Option"
                  name="correctOption"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Correct Option"
                  value={data}
                  onChange={(e) => handleChangeCorrectOption(e, i)}
                />
              </>
            ))}

          {options &&
            options?.map((option, i) => (
              <>
                <Box sx={{ mb: 2 }} style={{ fontWeight: "600" }}>
                  {/* {i + 0}. Options */}
                </Box>
                <TextField
                  sx={{
                    mb: 2,
                    "& .MuiInputBase-root": {
                      color: "text.secondary",
                    },
                    fieldset: { borderColor: "rgb(231, 235, 240)" },
                  }}
                  fullWidth
                  id="options"
                  label="Options"
                  name="options"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  placeholder="Options"
                  value={option}
                  onChange={(e) => handleChangeOptions(e, i)}
                />
              </>
            ))}
          <Button
            variant="contained"
            type="submit"
            color="primary"
            className="courses_desc"
            sx={{
              mt: 3,
              borderRadius: "5px",
              textTransform: "none",
              fontFamily: "Poppins, sans-serif",
              letterSpacing: ".1rem",
            }}
          >
            Edit Question
          </Button>
        </Container>
      </Modal>
    </div>
  );
};

export default EditQuestionModal;
