import React, { useEffect, useState } from "react";
import axios from "../../axiosInstance";
import { message, Modal } from "antd";
import Button from "@mui/material/Button";
import jsPDF from "jspdf";
import { Box } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

const QuestionPaperPDF = ({ id }) => {
  const [questions, setQuestions] = useState([]);
  const [examName, setExamName] = useState("");

  // Fetch exam data by ID
  const fetchExamById = async () => {
    try {
      const response = await axios.get(`/api/v1/exams/get-exam-by-id/${id}`);
      setQuestions(response.data.data.questions);
      setExamName(response.data.data.name);
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Failed to fetch question paper"
      );
    }
  };

  // Function to generate Question Paper PDF
  const generateQuestionPaperPDF = () => {
    Modal.confirm({
      title: "Download Question Paper",
      content: "Would you like to download the question paper PDF file?",
      okText: "Yes, Download",
      cancelText: "No, Cancel",
      onOk: () => {
        try {
          const doc = new jsPDF({
            orientation: "p",
            unit: "mm",
            format: "a4",
          });

          let yPos = 10;

          // Calculate center position for title
          const pageWidth = doc.internal.pageSize.width;
          const textWidth =
            (doc.getStringUnitWidth(examName) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          const centerX = (pageWidth - textWidth) / 2;

          // Add exam title centered
          doc.setFontSize(16);
          doc.text(examName, centerX, yPos);
          yPos += 15; // Increase yPos for title spacing

          // Add questions and options
          questions.forEach((question, index) => {
            // Add question number and name
            const questionText = `${index + 1}. ${question.name}`;
            const options = question.options.map((option, idx) => {
              return `${String.fromCharCode(97 + idx)}. ${option}`;
            });

            const content = doc.splitTextToSize(
              `${questionText}\n${options.join("\n")}`,
              doc.internal.pageSize.width - 20
            );

            if (
              yPos + doc.getTextDimensions(content).h >
              doc.internal.pageSize.height - 10
            ) {
              doc.addPage();
              yPos = 10;
            }
            doc.setFont("normal");
            doc.setFontSize(12);
            doc.text(content, 10, yPos);
            yPos += doc.getTextDimensions(content).h + 10;
          });

          // Save Question Paper PDF
          doc.save("question_paper.pdf");
          message.success("Question Paper PDF downloaded successfully!");
        } catch (error) {
          message.error("Error generating the Question Paper PDF.");
        }
      },
      onCancel() {
        console.log("Download canceled.");
      },
    });
  };

  // Function to generate Answer Key PDF
  // const generateAnswerKeyPDF = () => {
  //   Modal.confirm({
  //     title: "Download Answer Key",
  //     content: "Would you like to download the answer key PDF file?",
  //     okText: "Yes, Download",
  //     cancelText: "No, Cancel",
  //     onOk: () => {
  //       try {
  //         const doc = new jsPDF({
  //           orientation: "p",
  //           unit: "mm",
  //           format: "a4",
  //         });

  //         let yPos = 10;
  //         doc.setFontSize(16);
  //         doc.text(`Answer Key - ${examName}`, 10, yPos);
  //         yPos += 15;

  //         // Add questions and options with correct indication
  //         questions.forEach((question, index) => {
  //           // Add question number and name
  //           const questionText = `${index + 1}. ${question.name}`;
  //           const options = question.options.map((option, idx) => {
  //             return `${String.fromCharCode(97 + idx)}. ${option}`;
  //           });

  //           // Find the correct option index
  //           const correctOptionIndex = parseInt(question.correctOption);

  //           const content = doc.splitTextToSize(
  //             `${questionText}\n${options.join(
  //               "\n"
  //             )}\nCorrect Answer: ${String.fromCharCode(
  //               97 + correctOptionIndex
  //             )}. ${question.options[correctOptionIndex]}`,
  //             doc.internal.pageSize.width - 20
  //           );

  //           if (
  //             yPos + doc.getTextDimensions(content).h >
  //             doc.internal.pageSize.height - 10
  //           ) {
  //             doc.addPage();
  //             yPos = 10;
  //           }

  //           doc.setFontSize(12);
  //           doc.text(content, 10, yPos);
  //           yPos += doc.getTextDimensions(content).h + 10;
  //         });

  //         // Save Answer Key PDF
  //         doc.save("answer_key.pdf");
  //         message.success("Answer Key PDF downloaded successfully!");
  //       } catch (error) {
  //         message.error("Error generating the Answer Key PDF.");
  //       }
  //     },
  //     onCancel() {
  //       console.log("Download canceled.");
  //     },
  //   });
  // };

  const generateAnswerKeyPDF = () => {
    Modal.confirm({
      title: "Download Answer Key",
      content: "Would you like to download the answer key PDF file?",
      okText: "Yes, Download",
      cancelText: "No, Cancel",
      onOk: () => {
        try {
          const doc = new jsPDF({
            orientation: "p",
            unit: "mm",
            format: "a4",
          });
  
          let yPos = 10;
          doc.setFontSize(16);
          doc.text(`Answer Key - ${examName}`, 10, yPos);
          yPos += 15;
  
          // Add questions and options with correct indication
          questions.forEach((question, index) => {
            // Add question number and name
            const questionText = `${index + 1}. ${question.name}`;
            const options = question.options.map((option, idx) => {
              return `${String.fromCharCode(97 + idx)}. ${option}`;
            });
  
            // Handle single or multiple correct options
            const correctOptionIndices = Array.isArray(question.correctOption)
              ? question.correctOption
              : [question.correctOption];
  
            const correctAnswers = correctOptionIndices
              .map((optionIdx) => {
                return `${String.fromCharCode(97 + optionIdx)}. ${
                  question.options[optionIdx]
                }`;
              })
              .join(", ");
  
            const content = doc.splitTextToSize(
              `${questionText}\n${options.join(
                "\n"
              )}\nCorrect Answer(s): ${correctAnswers}`,
              doc.internal.pageSize.width - 20
            );
  
            // Check if the content fits on the current page or add a new page
            if (
              yPos + doc.getTextDimensions(content).h >
              doc.internal.pageSize.height - 10
            ) {
              doc.addPage();
              yPos = 10;
            }
  
            doc.setFontSize(12);
            doc.text(content, 10, yPos);
            yPos += doc.getTextDimensions(content).h + 10;
          });
  
          // Save Answer Key PDF
          doc.save("answer_key.pdf");
          message.success("Answer Key PDF downloaded successfully!");
        } catch (error) {
          message.error("Error generating the Answer Key PDF.");
        }
      },
      onCancel() {
        console.log("Download canceled.");
      },
    });
  };
  
  

  useEffect(() => {
    if (id) {
      fetchExamById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Box className="question-paper">
      <Button
        variant="contained"
        color="primary"
        onClick={generateQuestionPaperPDF}
        startIcon={<DownloadIcon />}
        className="courses_desc"
        sx={{
          mb: 2,
          justifyContent: "space-between",
          borderRadius: "5px",
          textTransform: "none",
          fontFamily: "Poppins, sans-serif",
          letterSpacing: ".1rem",
        }}
      >
        Question Paper
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={generateAnswerKeyPDF}
        startIcon={<DownloadIcon />}
        style={{ marginLeft: "10px" }}
        className="courses_desc"
        sx={{
          mb: 2,
          justifyContent: "space-between",
          borderRadius: "5px",
          textTransform: "none",
          fontFamily: "Poppins, sans-serif",
          letterSpacing: ".1rem",
        }}
      >
        Answer Key
      </Button>
    </Box>
  );
};

export default QuestionPaperPDF;
